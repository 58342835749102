import React, { useState } from "react";

import Link from "@mui/material/Link";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Logo from "./Logo";
import HamburgerMenuIcon from "./HamburgerMenuIcon";
import Navbar from "./Navbar";
import Grid from "@mui/material/Grid";
import logoBackground from "../../assets/images/Logo BG White.svg";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <Grid container p={0}>
      <Grid item md={12} xs={12} p={0}>
      <AppBar elevation={0  }>
      <Toolbar sx={{ height: 80, display: 'flex', position: 'relative', boxSizing: 'border-box'}}>
        <Grid item md={6} xs={6} p={0} sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'left', 
          alignItems: 'left',
          textAlign: 'left',
          zIndex: 11,
          marginLeft: {md: '10%', xs: '0'},
          '.header-logo-background': {
              position: 'absolute',
              objectFit: 'cover',
              height: 80,
              top: 0,
              left: 0,
              width: {xs: '80%', md: '50%'}
          },
          
          }}>
          <img src={logoBackground} className="header-logo-background"/>
          <Link href={`${window.location.origin}`}>
          <Logo size={isMobile ? 'small' : 'regular'}/>
          </Link>
        </Grid>
        <Grid item md={6} xs={6} p={0} sx={{}}>
          <Navbar/>
        </Grid>
      </Toolbar>
    </AppBar>
      </Grid>
      
    </Grid>
    
  );
};

export default Header;
