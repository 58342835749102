import { DeltekBottomSectionContainer } from "./DeltekComponents";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AttainFullResourceTransparencyImage from "../../../assets/images/attain-full-resource-transparency-image.png"
import EnableClientCollaborationImage from "../../../assets/images/enable-client-collaboration-image.png"
import ManageTasksAndTimeImage from "../../../assets/images/manage-tasks-and-time-image.png"
import GainFullVisibilityImage from "../../../assets/images/gain-full-visibility-image.png"
import TitleChevron from "../../../assets/images/chevron.png"
import LineToTheRight from "../../../assets/images/line-to-the-right.svg"
import LineToTheLeft from "../../../assets/images/line-to-the-left.svg"
import { useMediaQuery, useTheme } from "@mui/material";
const BottomSection = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return(
        <DeltekBottomSectionContainer>  
            <Grid container p={0} sx={{ 
                    alignItems: 'center', 
                    backgroundColor: '#00ACE2', 
                    textAlign: 'center', 
                    paddingTop: {sm: '100px', xs: '80px'},
                    paddingBottom: {sm: '100px', xs: '80px'}
                     }}>
                <Grid item sm={12} sx={{
                    '.attain-resource-transparency-image': {
                        maxWidth: isMobile ? '90vw' : '800px',
                        objectFit: 'cover',
                    },
                    '.attain-resource-transparency-title': {
                        fontFamily: 'Open Sans !important',
                        textAlign: isMobile ? 'left' : 'center',
                        fontSize: isMobile ? '20px' : '24px',
                        marginX: isMobile ? '20px' : '',
                        fontWeight: 600
                    },
                    '.attain-resource-transparency-text': {
                        fontFamily: 'Open Sans !important',
                        fontSize: isMobile ? '14px' : '18px',
                        marginTop: '25px',
                        fontWeight: 600,
                        textAlign: 'left !important',
                        marginLeft: {sm: '400px', xs: '50px'},
                        marginRight: {sm: '400px', xs: '50px'},
                    }
                }}>
                    <Typography className="attain-resource-transparency-title"><img src={TitleChevron} style={{ width: '10px', height: '24px', marginRight: '25px'}}/>Attain Full Resource Transparency</Typography>
                    <img src={AttainFullResourceTransparencyImage} className="attain-resource-transparency-image"/>
                    <Typography className="attain-resource-transparency-text">Accelerate Your Business's Productivity With The Ability To Balance Your Staff's Workload, And Optimize The Utilization Of Capital, Manpower And Resources</Typography>
                </Grid>
                {
                    isMobile ? (
                        <><Grid item sm={6} sx={{
                            justifyContent: 'right',
                            alignItems: 'right',
                            textAlign: 'right',
                            // paddingLeft: '200px',
                            paddingTop: '80px',
                            '.enable-client-collaboration-title': {
                                fontFamily: 'Open Sans !important',
                                fontSize: isMobile ? '20px' : '24px',
                                fontWeight: 600,
                                textAlign: 'left !important',
                                marginLeft: '30px'
                            },
                            '.enable-client-collaboration-image': {
                                maxWidth: { sm: '600px', xs: '300px' },
                                objectFit: 'cover',
                            }
                        }}>
                            <Typography className="enable-client-collaboration-title"><img src={TitleChevron} style={{ width: '10px', height: '24px', marginRight: '25px' }} />Enable Client Collaboration</Typography>
                            <img src={EnableClientCollaborationImage} className="enable-client-collaboration-image" />
                        </Grid><Grid item sm={6} sx={{
                            '.enable-client-collaboration-text': {
                                fontFamily: 'Open Sans !important',
                                marginTop: '25px',
                                fontSize: isMobile ? '14px' : '18px',
                                textAlign: 'left !important',
                                marginX: '10%'
                            }
                        }}>
                                <Typography className="enable-client-collaboration-text">Workbook Supports Closer Collaboration With Clients To Help Improve Communication And Manage Expectations For Smoother Flow Of Projects</Typography>
                                <img src={LineToTheRight} style={{ width: "100%", paddingTop: '20px', padding: '0 10%' }} />
                            </Grid></>
                    ) :
                    (
                        <><Grid item sm={6} sx={{
                                justifyContent: 'right',
                                alignItems: 'right',
                                textAlign: 'right',
                                // paddingLeft: '200px',
                                paddingTop: '80px',
                                '.enable-client-collaboration-image': {
                                    maxWidth: { sm: '600px', xs: '300px' },
                                    objectFit: 'cover',
                                }
                            }}>
                                <img src={EnableClientCollaborationImage} className="enable-client-collaboration-image" />
                            </Grid><Grid item sm={6} sx={{
                                '.enable-client-collaboration-title': {
                                    fontFamily: 'Open Sans !important',
                                    fontSize: isMobile ? '20px' : '24px',
                                    fontWeight: 600,
                                    textAlign: 'left !important',
                                    marginLeft: '30px'
                                },
                                '.enable-client-collaboration-text': {
                                    fontFamily: 'Open Sans !important',
                                    marginTop: '25px',
                                    fontSize: isMobile ? '14px' : '18px',
                                    textAlign: 'left !important',
                                    marginLeft: '65px',
                                    marginRight: '300px'
                                }
                            }}>
                                    <Typography className="enable-client-collaboration-title"><img src={TitleChevron} style={{ width: '10px', height: '24px', marginRight: '25px' }} />Enable Client Collaboration</Typography>
                                    <Typography className="enable-client-collaboration-text">Workbook Supports Closer Collaboration With Clients To Help Improve Communication And Manage Expectations For Smoother Flow Of Projects</Typography>
                                    <img src={LineToTheRight} style={{ width: "100%", paddingTop: '20px', paddingRight: '300px' }} />
                                </Grid></>
                    )
                }

                    {
                        isMobile ? (
                            <><Grid item sm={6} sx={{
                            paddingTop: '80px',
                            alignItems: 'right',
                            textAlign: 'right',
                            // paddingLeft: '200px',
                            '.manage-tasks-and-time-title': {
                                fontFamily: 'Open Sans !important',
                                fontSize: isMobile ? '20px' : '24px',
                                fontWeight: 600,
                                textAlign: 'left !important',
                                marginLeft: '30px'
                            },
                            '.manage-tasks-and-time-image': {
                                maxWidth: { sm: '600px', xs: '300px' },
                                objectFit: 'cover',
                                marginX: '10%',
                            }
                        }}>
                            <Typography className="manage-tasks-and-time-title"><img src={TitleChevron} style={{ width: '10px', height: '24px', marginRight: '25px' }} />Manage Tasks And Time</Typography>
                            <img src={ManageTasksAndTimeImage} className="manage-tasks-and-time-image" />
                        </Grid><Grid item sm={6} sx={{
                            '.manage-tasks-and-time-text': {
                                fontFamily: 'Open Sans !important',
                                marginTop: '25px',
                                fontSize: isMobile ? '14px' : '18px',
                                textAlign: 'left !important',
                                marginX: '10%'
                            }
                        }}>


                                <Typography className="manage-tasks-and-time-text">Integrated Task Management And Time Entry Allows Team Members To Streamline Their Agile Workflow, So They Can Focus On The Creative Process.</Typography>
                                <img src={LineToTheLeft} style={{ width: "100%", paddingTop: '20px', padding: '0 10%' }} />
                            </Grid></>
                            
                        ) : 
                        (
                            <><Grid item sm={6} sx={{
                                alignItems: 'right',
                                justifyContent: 'right',
                                '.manage-tasks-and-time-title': {
                                    fontWeight: 600,
                                    fontFamily: 'Open Sans !important',
                                    fontSize: '24px',
                                    textAlign: 'left !important',
                                    marginRight: '30px',
                                    marginLeft: '350px'
                                },
                                '.manage-tasks-and-time-text': {
                                    fontFamily: 'Open Sans !important',
                                    fontSize: '18px',
                                    marginTop: '30px',
                                    textAlign: 'left !important',
                                    marginRight: '30px',
                                    marginLeft: '385px'
                                }
                            }}>

                                <Typography className="manage-tasks-and-time-title"><img src={TitleChevron} style={{ width: '10px', height: '24px', marginRight: '25px' }} />Manage Tasks And Time</Typography>
                                <Typography className="manage-tasks-and-time-text">Integrated Task Management And Time Entry Allows Team Members To Streamline Their Agile Workflow, So They Can Focus On The Creative Process.</Typography>
                                <img src={LineToTheLeft} style={{ width: "100%", paddingTop: '20px', paddingLeft: '350px'}} />
                            </Grid><Grid item sm={6} sx={{
                                paddingTop: '80px',
                                alignItems: 'left',
                                textAlign: 'left',
                                justifyContent: 'center',
                                // paddingLeft: '200px',
                                '.manage-tasks-and-time-image': {
                                    maxWidth: { sm: '600px', xs: '300px' },
                                    objectFit: 'cover',
                                }
                            }}>
                                    <img src={ManageTasksAndTimeImage} className="manage-tasks-and-time-image" />
                                </Grid></>
                        )
                    }

                    {
                        isMobile ? (
                            <><Grid item sm={6} sx={{
                            paddingTop: '80px',
                            justifyContent: 'right',
                            alignItems: 'right',
                            textAlign: 'right',
                            // paddingLeft: '200px',
                            '.gain-full-visibility-title': {
                                fontFamily: 'Open Sans !important',
                                fontSize: isMobile ? '20px' : '24px',
                                fontWeight: 600,
                                textAlign: 'left !important',
                                marginX: '10%'
                            },
                            '.gain-full-visibility-image': {
                                maxWidth: { sm: '600px', xs: '300px' },
                                objectFit: 'cover',
                                marginX: '10%',
                            }
                        }}>
                            <Typography className="gain-full-visibility-title"><img src={TitleChevron} style={{ width: '10px', height: '24px', marginRight: '25px' }} />Gain Full Visibility Into Project Performance</Typography>
                            <img src={GainFullVisibilityImage} className="gain-full-visibility-image" />
                        </Grid><Grid item sm={6} sx={{
                            '.gain-full-visibility-text': {
                                fontFamily: 'Open Sans !important',
                                marginTop: '25px',
                                fontSize: isMobile ? '14px' : '18px',
                                textAlign: 'left !important',
                                marginX: '10%'
                            }
                        }}>

                                <Typography className="gain-full-visibility-text">Achieve Clarity On Project Profitability And Cost Control To More Accurately Future Accomplish Budget Planning And Revenue Forecasting.</Typography>
                                <img src={LineToTheRight} style={{ width: "100%", paddingTop: '20px', padding: '0 10%' }} />
                            </Grid></>
                        ) : (
                            <><Grid item sm={6} sx={{
                                paddingTop: '80px',
                                justifyContent: 'right',
                                alignItems: 'right',
                                textAlign: 'right',
                                // paddingLeft: '200px',
                                '.gain-full-visibility-image': {
                                    maxWidth: { sm: '600px', xs: '300px' },
                                    objectFit: 'cover',
                                }
                            }}>
                                <img src={GainFullVisibilityImage} className="gain-full-visibility-image" />
                            </Grid><Grid item sm={6} sx={{
                                '.gain-full-visibility-title': {
                                    fontFamily: 'Open Sans !important',
                                    fontWeight: 600,
                                    fontSize: '24px',
                                    textAlign: 'left !important',
                                    marginLeft: '30px',
                                    marginRight: '300px'
                                },
                                '.gain-full-visibility-text': {
                                    fontFamily: 'Open Sans !important',
                                    marginTop: '25px',
                                    fontSize: '18px',
                                    textAlign: 'left !important',
                                    marginLeft: '65px',
                                    marginRight: '300px'
                                }
                            }}>
                                    <Typography className="gain-full-visibility-title"><img src={TitleChevron} style={{ width: '10px', height: '24px', marginRight: '25px' }} />Gain Full Visibility Into Project Performance</Typography>
                                    <Typography className="gain-full-visibility-text">Achieve Clarity On Project Profitability And Cost Control To More Accurately Future Accomplish Budget Planning And Revenue Forecasting.</Typography>
                                    <img src={LineToTheRight} style={{ width: "100%", paddingTop: '20px', paddingRight: '300px' }} />
                                </Grid></>
                        )
                    }
                
                
            </Grid>
            
        </DeltekBottomSectionContainer>
    )
}

export default BottomSection;