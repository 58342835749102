import {
    ArticleState,
    ArticleAction,
    SET_ARTICLE_STATE
} from './types';

const INITIAL_STATE: ArticleState = {
    articles: [],
    mostPopularArticles: [],
    videos: [],
    articleSelect: 'top-post',
    loading: false
}

const articleReducers = (state = INITIAL_STATE, action: ArticleAction): ArticleState => {
    switch (action.type) {
        case SET_ARTICLE_STATE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

export default articleReducers;