import { BlogResourcesContainer } from "./BlogComponents";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AttainFullResourceTransparencyImage from "../../../assets/images/attain-full-resource-transparency-image.png"
import EnableClientCollaborationImage from "../../../assets/images/enable-client-collaboration-image.png"
import ManageTasksAndTimeImage from "../../../assets/images/manage-tasks-and-time-image.png"
import GainFullVisibilityImage from "../../../assets/images/gain-full-visibility-image.png"
import TitleChevron from "../../../assets/images/chevron.png"
import ArticleBarImage from "../../../assets/images/blog-articles-bar.png"
import {resources} from "../../../constants/resources"
import BlogResourcesList from "./BlogResourcesList";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Pagination, useMediaQuery } from "@mui/material";
const BlogResources = () => {
    const allResources = resources
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    //PAGINATION
    const total = 10;
    const [page, setPage] = useState(1);
    const limit = 6;
    const startIndex = (page - 1) * limit;
    const endIndex = page * limit;
    const dataToDisplay = allResources.slice(startIndex, endIndex);
    return(
        <BlogResourcesContainer>
            <Grid container p={0} sx={{
                paddingLeft: '10%',
                paddingRight: '10%',
                backgroundColor: '#0061A1',
                width: '100%',
                alignItems: 'center !important',
                justifyContents: 'center !important',
                textAlign: 'center !important',
                paddingTop: '80px',
                paddingBottom: '100px' }}>
                <Grid item sm={12} sx={{
                    textAlign: 'left',
                    display: 'flex',
                    flexDirection: 'row',

                    '.resources-title': {
                        fontFamily: 'Roboto Slab !important',
                        fontSize: isMobile ? '24px' : '36px',
                        fontWeight: 300,  
                        
                        
                    }
                }}>
                    <Typography className="resources-title">Resources<img src={ArticleBarImage} style={{objectFit: 'cover', width: 'auto', maxWidth: isMobile ? '60%' : '80%', paddingLeft:'10px'}}/></Typography>
                </Grid>
                <Grid item sm={12} >
                    {   
                        
                        dataToDisplay.map((a) => {
                            console.log(dataToDisplay)
                            return(
                                  <BlogResourcesList
                                    title={a.title}
                                    image={a.image}
                                    resourceShortDescription={a.shortDetail}
                                    resourceUrl={a.url}
                                  />
                            )
                        })
                    }
                    
                </Grid>
            </Grid>
            <Pagination/>
        </BlogResourcesContainer>
    )
}

export default BlogResources;

