export const articles = [
    {
        "title": "Why you should develop a long-term data-driven resource strategy for your organization",
        "image": "https://opsolutions.biz/public/img/articles/Deltek%20Resource%20management%20.webp",
        "shortDetail": "Data-driven recruitment may sound like yet another tech buzzword. A trend that's going to pass. But is it?",
        "url": "https://opsolutions.biz/articles?title=Why%20you%20should%20develop%20a%20long-term%20data-driven%20resource%20strategy%20for%20your%20organization"
    },
    {
        "title": "Resource Management Made Easy With Deltek WorkBook",
        "image": "https://opsolutions.biz/public/img/articles/Resource-management-made-easy-with-deltek.webp",
        "shortDetail": "When talking about executing projects, we often discuss the importance of goals. But there’s one critical piece of the equation that is often overlooked. And doing so can spell certain doom for any project, no matter how brilliant the initial goals are. This crucial element is resource management. Simply put, resource management is a system within project management for the allocation of various resources the organization uses to execute its projects effectively. These resources can be divided into two distinct groups: tangible and intangible resources. Among tangible resources are finances, inventory, materials. Intangible resources are people, who work on the project directly, and time. Sometimes, there are referred to as the 5 M's: man, materials, machines, minutes and money.",
        "url": "https://opsolutions.biz/articles?title=Resource%20Management%20Made%20Easy%20With%20Deltek%20WorkBook"
    },
    {
        "title": "How Are You Coping and Managing Your Business During These Unprecedented Times?",
        "image": "https://opsolutions.biz/public/img/articles/coping-managing.webp",
        "shortDetail": "Several years ago, if anyone had said work from home is a new norm, they likely would have been ridiculed. But in the wake of the COVID-19 pandemic, employees increasingly go to the office by staying at home. Coping with this shift has proven difficult as companies seek to keep employees engaged and manage tasks. Fortunately, digital transformation can be quickly and easily implemented to ensure smooth operations - even with a remote workforce.",
        "url": "https://opsolutions.biz/articles?title=How%20Are%20You%20Coping%20and%20Managing%20Your%20Business%20During%20These%20Unprecedented%20Times%3F"
    },
    {
        "title": "Deltek WorkBook’s Customer Story",
        "image": "https://opsolutions.biz/public/img/articles/deltek-workbook.webp",
        "shortDetail": "One of Deltek WorkBook customers who is a global creative agency that has more than 800 social experts in 15 offices. Their creative agency believe in people, not platforms and the power of social insight to drive business value. They call this social thinking. They are a part of the growing Blue Focus global network and has over 800 people in 11 offices in New York, London, Paris, Milan, Munich, Berlin, Singapore, Shanghai, Beijing, Dubai and Sydney.",
        "url": "https://opsolutions.biz/articles?title=Deltek%20WorkBook%E2%80%99s%20Customer%20Story"
    },
    {
        "title": "How Do You Empower Your Team With Task Management Tool?",
        "image": "https://opsolutions.biz/public/img/articles/task-management.webp",
        "shortDetail": "Nearly every part of today's organizations is powered by technology, from individual employee work to overall business operations. Technology may be used to improve communication, generate efficiency, and increase production when properly networked and applied in the company. With the automation and globalization of business, managing company operations has become increasingly difficult and challenging for all small and large firms throughout the world. Looking for help with such matters does not imply inefficiency, but it is currently seen as a strategic move and decision for achieving effective corporate operations.",
        "url": "https://opsolutions.biz/articles?title=How%20Do%20You%20Empower%20Your%20Team%20With%20Task%20Management%20Tool%3F"
    },
    {  
        "title": "Roadblock To An Effective Agency Management",
        "image": "https://opsolutions.biz/public/img/articles/effective-agency-management.webp",
        "shortDetail": "Managing your organization's operations in this age of digital transformation will almost certainly be significantly easier than traditional operations management. One of the advantages of digital transformation is the ability to monitor how each department or activity operates at the same time. However, while automating your operations is advantageous, it also exposes your organization to risks, which is obviously not good for any company seeking to maintain a smooth operation.",
        "url": "https://opsolutions.biz/articles?title=Roadblock%20To%20An%20Effective%20Agency%20Management"
    },
    {
        "title": "How Innovative Digital Solutions Can Make Working From Home More Efficient",
        "image": "https://opsolutions.biz/public/img/articles/innovative-digital.webp",
        "shortDetail": "We know your business is trying to operate as close to normal as possible in the wake of coronavirus. For many, this means working from home and trying to juggle multiple responsibilities at once. Help can be found in digital solutions designed to streamline operations and reinstitute a feeling of connectivity.",
        "url": "https://opsolutions.biz/articles?title=How%20Innovative%20Digital%20Solutions%20Can%20Make%20Working%20From%20Home%20More%20Efficient"
    },
]