import { HomeAboutSectionContainer } from "./HomeComponents"
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Logo from "../../common/Logo";
import shapeHexagon from "../../../assets/images/Shape Hexagons.svg"
import shapeChevrons from "../../../assets/images/Shape Chevrons.svg"
import icoChart from "../../../assets/images/Ico Chart.svg"
import icoBulg from "../../../assets/images/Icon Bulg.svg"
import iconWorld from "../../../assets/images/Icon World.svg"
import iconServer from "../../../assets/images/Icon Server.svg"
import DeltekVideo from "../../../assets/videos/DeltekWorkbook.mp4"
import DeltekVideoPoster from "../../../assets/images/deltek-video-poster.png"
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
// interface HomeTitleHeaderProps {
//     titleBlack: string;
//     titleGray: string;
//     titleBlackFeatured?: boolean;
// }

const HomeAboutSection = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <HomeAboutSectionContainer>
            <Grid container p={0} sx={{
                backgroundColor: '#00ACE2'
            }}>
                <Grid container p={0} sx={{
                    backgroundColor: '#FFFFFF',
                    marginY: {md: '144px'} ,
                    marginX: {md: '10%'}, 
                    
                    }}>
                    <Grid item sm={12} p={0} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        padding: '50px 50px',
                        // marginLeft: isMobile ? '15px' : '0'
                    }}>
                        <Logo size={isMobile ? 'regular' : 'medium'}/>
                    </Grid>
                    <Grid item sm={12} p={0} sx={{
                        display: 'grid',
                        alignContent: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        backgroundColor: '#D9F3F9'
                    }}>
                        <video src={DeltekVideo} style={{ display: 'flex', objectFit: 'cover', width: '100%'}} controls/>
                    </Grid>
                    <Grid item sm={6} p={0} sx={{
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: 'center',
                        padding: {md: '50px 50px' , xs: '50px 30px'},
                        backgroundColor: '#2F6281'
                    }}>
                        <img src={shapeHexagon} style={{
                            position: 'absolute',
                            left: isMobile ? '5%' : '9.3%',
                            top: isMobile ? '18%' : '67%',
                            opacity: '50%'
                            
                        }}/>
                        <Typography sx={{
                            fontSize: '18px',
                            fontFamily: 'Open Sans !important',
                            textAlign: 'left !important',
                            color: '#D9F3F9'
                        }}>OPSOLUTIONS is delighted to collaborate with our incredible partner Deltek Workbook, where we strive to provide only the best solutions to you. <br/><br/>OPSOLUTIONS set our to create great things in 2008, and we built an organization to foster greatness. <br/><br/>Providing end-to-end service - we are solutions provider, an IT and support provider, and a business partner who steers businesses toward opportunities and away from risks. <br/><br/>We strive to defy convention and outperform expectations. We drive new markets, grow brands, and maximize market share for customers through our regional presence in Asia - Singapore, Malaysia, Philippines, Indonesia, China, Thailand, and Vietnam.</Typography>
                    </Grid>
                    <Grid item sm={6} p={0} sx={{
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: 'center',
                        padding: {md: '50px 50px' , xs: '50px 30px'},
                        backgroundColor: '#2F6281'
                    }}>
                        <Typography sx={{
                            fontSize: '18px',
                            fontFamily: 'Open Sans !important',
                            textAlign: 'left !important',
                            color: '#D9F3F9'
                        }}>Deltek Workbook is a total agency management system designed by agency veterans that provides best practice solutions to help your agency grow.<br/><br/> 
                        By integrating projects, people, and finance from start to finish in a single cloud-based solution, you gain real-time control, insight, and visibility, allowing you to achieve digital transformation, manage a distributed workforce, and lay the groundwork for business maturity and growth.<br/><br/> Take it a step further by integrating online proofing with Deltek ConcepShare and getting up and running faster with a Power Launch implementation.</Typography>
                        <img src={shapeChevrons} style={{
                            position: 'absolute',
                            right: isMobile ? '3%' : '9%',
                            bottom: isMobile ? '35%' : '20%',
                            
                        }}/>
                        <img src={icoChart} style={{ height: isMobile ? '40px' : '', width: isMobile ? '50px' : '', position: 'absolute', bottom: isMobile ? '-1.75%' : '5%', right: isMobile ? '30%' : '40%', zIndex: 10}}/><img src={icoBulg} style={{ height: isMobile ? '40px' : '', width: isMobile ? '50px' : '', position: 'absolute', bottom: isMobile ? '-.5%' : '7%', right: isMobile ? '20%' : '36%', zIndex: 10}}/><img src={iconWorld} style={{ height: isMobile ? '40px' : '', width: isMobile ? '50px' : '', position: 'absolute', bottom: isMobile ? '-1.75%' : '5%', right: isMobile ? '10%' : '32%', zIndex: 10}}/><img src={iconServer} style={{ height: isMobile ? '40px' : '', width: isMobile ? '50px' : '', position: 'absolute', bottom: isMobile ? '-.5%' : '7%', right: isMobile ? '0%' : '28%', zIndex: 10}}/>      
                    </Grid>    
                </Grid>
            </Grid>
            
        </HomeAboutSectionContainer>
    )
}

export default HomeAboutSection;