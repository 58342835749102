import { Link, NavLink, useLocation } from "react-router-dom";
import InputBase from "@mui/material/InputBase";
import { Container, Stack, IconButton, Box, Paper } from "@mui/material";
import { styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";

// import MenuToggler from "../lib/MenuToggler";
// // import MobileNavigation from "./MobileNavigation";
import { appColors } from "../../theme";
import { useState } from "react";
import { navItemsv3 } from "../../constants";

const AppLink = styled(NavLink)(({ theme }) => ({
	color: "white",
	textDecoration: "none",
	position: "relative",
	fontSize: "1.1rem",
	letterSpacing: "0.5px",

	"&:after": {
		position: "absolute",
		bottom: -5,
		right: "50%",
		content: '""',
		width: '100%',
		borderRadius: 2,
		height: 1,
		transition: "transform 0.3s",
		transform: "translateX(50%) scaleX(0)",
	},

	"&:hover:after": {
		transition: "transform 0.3s",
		transform: "translateX(50%) scaleX(1)",
		backgroundColor: `${appColors.white}`,
	},

	"&:hover": {
		color: `${appColors.white}`,
	},

	"&.active::after": {
		borderRadius: 2,
		backgroundColor: `${appColors.white}`,
		transform: "translateX(50%) scaleX(1)",
	},
}));


const NavigationBarv3 = () => {
	const [mobileNavShown, setMobileNavShown] = useState(false);
	const [clickSearch, setClickSearch] = useState(false);

	const toggleMobileNav = () => setMobileNavShown((val) => !val);

	return (
		<Stack
			position="sticky"
			component="nav"
			zIndex="10"
			top="0%"
			sx={{ px: { xs: 1, lg: 6 }, pb: { xs: 2, md: 0 } }}
			pt={1}
			width="100vw"
			color="white"
		>
			<Container maxWidth={false}>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-b"
					pb={1}
					sx={{
						borderBottom: {
							xs: "none",
							md: "none",
						},
					}}
				>
							<Stack
								component="ul"
								direction="row"
								alignItems="center"
								gap={3}
								flex={1}
								// ml="auto"
								sx={{ display: { xs: "none", md: "flex" } }}
							>
								{navItemsv3.map((nav) => (
									<Stack component="li" key={nav.label}>
										<AppLink to={nav.path} exact={nav.path === "/"}>
											{nav.label}
										</AppLink>
									</Stack>
								))}
							</Stack>

							{/* <MenuToggler onToggle={toggleMobileNav} /> */}
					
				</Stack>
			</Container>
			{/* Mobile nav here */}
			{/* <MobileNavigation open={mobileNavShown} onClose={toggleMobileNav} /> */}
		</Stack>
	);
};

export default NavigationBarv3;
