export const offices = {
    "Singapore": {
        "address": "116 Changi Road, #03-11 WIS@Changi, Singapore 419718",
        "phone": "+65 - 64400874‍",
        "email": "sales.sg@opsolutions.biz",
    },
    "Philippines": {
        "address": "Carolina Center #204 - 207,213 Sta Rosa - Tagaytay Road Silang Cavite, Philippines 4118",
        "phone": "+63 - 9464048010",
        "email": "sales.ph@opsolutions.biz",
    },
    "Malaysia": {
        "address": "Office Productivity Solution Sdn Bhd.33-1 Menara Keck Seng,203 Jalan Bukit Bintang,Bukit Bintang, 55100 Kuala Lumpur.",
        "phone": "+603 - 21499334",
        "email": "sales.my@opsolutions.biz",
    },
    "China": {
        "address": "OPS China",
        "phone": "+1234567890",
        "email": "sales.ch@opsolutions.biz",
    },
    "Vietnam": {
        "address": "OPS Vietnam",
        "phone": "+1234567890",
        "email": "sales.vn@opsolutions.biz",
    },
    "Thailand": {
        "address": "152 Kian Gwan 3, 9 Floor, Witthayu Road, Lumphini Patumwan, Bangkok 10330",
        "phone": "+603 - 21499334",
        "email": "sales.th@opsolutions.biz",
    },
}
