export const navItemsv3 = [
    {
      label: "Home",
      path: "/Home",
    },
    {
      label: "Deltek Workbook",
      path: "/DeltekWorkbook",
    },
    {
      label: "Blog",
      path: "/Blog",
    },
    {
      label: "Contact Us",
      path: "/ContactUs",
    },
  ];
  