import { HomeAboutSectionContainer } from "./HomeComponents"
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useCallback, useEffect, useRef, useState } from "react";
import OfficeMap from "./OfficeMap"
import {offices} from "../../../constants/offices"
import { constant } from "lodash";
import LocationLogo from "../../../assets/images/Shape Location.svg"
import PhoneLogo from "../../../assets/images/Shape Phone.svg"
import EmailLogo from "../../../assets/images/Shape Email.svg"
import GetInTouchLogo from "../../../assets/images/Shape Paper Plane.svg"
import Box from "@mui/material/Box";
import bottomShapeHexagon from "../../../assets/images/Shape Hexagon.svg"
import Link from "@mui/material/Link";
import  emailjs from "@emailjs/browser";
import { DialogProps, Dialog, DialogActions, Button, DialogTitle, DialogContentText, TextField, useTheme, useMediaQuery, Select, MenuItem } from "@mui/material";
// interface HomeTitleHeaderProps {
//     titleBlack: string;
//     titleGray: string;
//     titleBlackFeatured?: boolean;
// }
interface SubmitButtonIconProps {
    height: string;
    width: string;
}
const SubmitButtonIcon = (props: SubmitButtonIconProps) => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 47.98 42">
        <g id="Shape:_Paper_Plane" data-name="Shape: Paper Plane" transform="translate(-1253.336 -1958)">
    <path id="Icon_ionic-ios-send" data-name="Icon ionic-ios-send" d="M27.749,4.557,4.8,14.559a.526.526,0,0,0,.019.956l6.207,3.507a1,1,0,0,0,1.144-.113L24.411,8.357c.081-.069.275-.2.35-.125s-.044.269-.113.35L14.058,20.51a1,1,0,0,0-.1,1.194l4.057,6.508a.528.528,0,0,0,.95-.013L28.455,5.25A.526.526,0,0,0,27.749,4.557Z" transform="translate(1260.823 1964.918)" fill="#F5F5F5"/>
  </g>
</svg>
    )
    

}
const MessageUsButton = () => {
    return(
        <svg id="Button:_Message_Us" data-name="Button: Message Us" xmlns="http://www.w3.org/2000/svg" width="159" height="36" viewBox="0 0 159 36">
  <g id="Rectangle_59" data-name="Rectangle 59" fill="#fff" stroke="#707070" stroke-width="1">
    <rect width="1" height="36" stroke="none"/>
    <rect x="0.5" y="0.5" height="35" fill="none"/>
  </g>
  <g id="Group_8500" data-name="Group 8500" transform="translate(61.099 10)">
    <text id="Message_Us" data-name="Message Us" transform="translate(7.002 13)" fill="#211f20" font-size="12" font-family="RobotoSlab-Light, Roboto Slab" font-weight="300"><tspan x="-33" y="0">Message Us</tspan></text>
    <path id="Icon_ionic-ios-send" data-name="Icon ionic-ios-send" d="M14.962,4.527,4.637,9.027a.237.237,0,0,0,.008.43l2.793,1.578a.45.45,0,0,0,.515-.051L13.46,6.237c.037-.031.124-.09.158-.056s-.02.121-.051.158L8.8,11.705a.449.449,0,0,0-.045.537l1.825,2.928a.238.238,0,0,0,.428-.006L15.28,4.839A.237.237,0,0,0,14.962,4.527Z" transform="translate(47.498 -1.902)" fill="#211f20"/>
  </g>
  <g id="Border" fill="none" stroke="#211f20" stroke-width="1">
    <rect width="159" height="36" stroke="none"/>
    <rect x="0.5" y="0.5" width="158" height="35" fill="none"/>
  </g>
</svg>

    )
}

const HoveredMessageUsButton = () => {
    return (
        <svg id="Button:_Message_Us" data-name="Button: Message Us" xmlns="http://www.w3.org/2000/svg" width="159" height="36" viewBox="0 0 159 36">
  <rect id="Rectangle_59" data-name="Rectangle 59" width="159" height="36" fill="#0298d6"/>
  <g id="Group_8500" data-name="Group 8500" transform="translate(58.498 10)">
    <text id="Message_Us" data-name="Message Us" transform="translate(7.002 13)" fill="#d9f3f9" font-size="12" font-family="RobotoSlab-Light, Roboto Slab" font-weight="300"><tspan x="-33" y="0">Message Us</tspan></text>
    <path id="Icon_ionic-ios-send" data-name="Icon ionic-ios-send" d="M20,4.539,4.7,11.207a.351.351,0,0,0,.013.638l4.138,2.338a.667.667,0,0,0,.763-.075l8.16-7.035c.054-.046.183-.133.233-.083s-.029.179-.075.233l-7.06,7.952a.665.665,0,0,0-.067.8l2.7,4.338a.352.352,0,0,0,.633-.008L20.471,5A.351.351,0,0,0,20,4.539Z" transform="translate(47.498 -4.503)" fill="#d9f3f9"/>
  </g>
  <g id="Border" fill="none" stroke="#4097d1" stroke-width="1">
    <rect width="159" height="36" stroke="none"/>
    <rect x="0.5" y="0.5" width="158" height="35" fill="none"/>
  </g>
</svg>

    )
}
const HomeOurOfficeSection = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const handleFormSubmit = () => {
        console.log("Submitted")
        console.log(modalFirstName, modalCompanyName, modalEmail, modalMessage)
        var emailParams = {
            sender_name: `${modalFirstName}`,
            sender_companyName: `${modalCompanyName}`,
            sender_email: `${modalEmail}`,
            sender_message: `${modalMessage}`,
            handler_email: 'frederick.magnaye@opsolutions.biz'
        }
        console.log(emailParams)
        // emailjs.send('service_no3xy6r', 'template_b3y42zd', emailParams, 'glK4hrMDnuTI_lM4M');
        handleClose()
        setPromptOpen(true)
    }
    
    const handleClose = () => { 
        setOpen(false) 
        setPromptOpen(false) 
    }
    const [maxWidth, setMaxWidth] = useState<DialogProps['maxWidth']>('sm');
      const [open, setOpen] = useState(false)
      const [promptOpen, setPromptOpen] = useState(false)
      const [hovered, setHovered] = useState(false)
      const [buttonHovered, setButtonHovered] = useState(false)
      const [map, setMap] = useState(null)
      const [coordinates, setCoordinates] = useState({ lat: 1.3162970195537025, lng: 103.90117731449845 })
      const [countryClicked, setCountryClicked] = useState("Singapore")
      const [address, setAddress] = useState("")
      const [phone, setPhone] = useState("")
      const [email, setEmail] = useState("")
      const countries = ["Singapore", "Philippines", "Malaysia", "China", "Vietnam", "Thailand"]
      const allOffices = offices
      const [modalFirstName, setModalFirstName] = useState("")
      const [modalCompanyName, setModalCompanyName] = useState("")
      const [modalEmail, setModalEmail] = useState("")
      const [modalMessage, setModalMessage] = useState("")
    
      
        useEffect(() => {
            switch (countryClicked) {
                case "Singapore" :
                    setCoordinates({lat: 1.3162952, lng: 103.8990029})
                    setAddress(allOffices.Singapore.address)
                    setPhone(allOffices.Singapore.phone)
                    setEmail(allOffices.Singapore.email)
                    break
                case "Philippines" :
                    setCoordinates({lat: 14.2199153, lng: 121.0339851})
                    setAddress(allOffices.Philippines.address)
                    setPhone(allOffices.Philippines.phone)
                    setEmail(allOffices.Philippines.email)
                    break
                case "Malaysia" :
                    setCoordinates({lat: 3.1477139, lng: 101.7128269})
                    setCountryClicked("Malaysia")
                    setAddress(allOffices.Malaysia.address)
                    setPhone(allOffices.Malaysia.phone)
                    setEmail(allOffices.Malaysia.email)
                    break
                case "China" :
                    setCoordinates({lat: 1.3162952, lng: 103.8990029})
                    setAddress(allOffices.China.address)
                    setPhone(allOffices.China.phone)
                    setEmail(allOffices.China.email)
                    break
                case "Vietnam" :
                    setCoordinates({lat: 1.3162952, lng: 103.8990029})
                    setAddress(allOffices.Vietnam.address)
                    setPhone(allOffices.Vietnam.phone)
                    setEmail(allOffices.Vietnam.email)
                    break
                case "Thailand" :
                    setCoordinates({lat: 1.3162952, lng: 103.8990029})
                    setAddress(allOffices.Thailand.address)
                    setPhone(allOffices.Thailand.phone)
                    setEmail(allOffices.Vietnam.email)
                    break
            }
        }, [countryClicked])
      return (
            <HomeAboutSectionContainer>
            <Grid container p={0} sx={{
                backgroundColor: '#F5F5F5',
                textAlign: isMobile ? 'center !important' : 'left',
                justifyContent: isMobile ? 'center !important' : 'left',
                alignItems: isMobile ? 'center !important' : 'left',
                
                
            }}>
                    <Grid item sm={12} p={0} sx={{
                        display: 'grid',
                        marginTop: '50px',
                        marginBottom: '10px',
                        paddingLeft: isMobile ? '0' : '10%',
                    }}>
                    <Typography sx={{
                        fontSize: '32px',
                        fontFamily: 'Roboto Slab !important',
                        fontWeight: 'bold',
                        color: '#32323C',
                        
                    }}>Our Offices</Typography>
                    </Grid>
            </Grid>
            <Grid container p={0} sx={{
                        backgroundColor: '#F5F5F5',
                        textAlign: isMobile ? 'center !important' : 'left',
                        justifyContent: isMobile ? 'center !important' : 'left',
                        alignItems: isMobile ? 'center !important' : 'left',
    
                        paddingLeft: isMobile ? '0' : '10%',
                        'ul': {
                            position: 'relative',
                            display: 'flex',
                        },
                        'li': {
                            listStyle: 'none'
                        },
                        '.countries-list-item':{
                            position: 'relative',
                            display: 'block',
                            margin: '20px 0',
                            padding: '0px 20px',
                            textDecoration: 'none',
                            color: '#32323C',
                            fontSize: '18px',
                            fontFamily: 'Roboto Slab !important',
                            // fontWeight: '600',
                            transition: '.5s',
                            zIndex: 1
                        },
                        '.countries-list-item:before': {
                            content: "''",
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            width: '100%',
                            height: '100%',
                            borderTop: '2px solid #0069A9',
                            borderBottom: '2px solid #0069A9',
                            transform: 'scaleY(2)',
                            opacity: '0',
                            transition: '.3s'
                        },
                        '.countries-list-item:after': {
                            content: "''",
                            position: 'absolute',
                            top: '2px',
                            left: '0',
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#0069A9',
                            transform: 'scale(0)',
                            opacity: '0',
                            transition: '.3s',
                            zIndex: -1
                        },
                        '.countries-list-item:hover': {
                            color: '#ffffff',
                            '&:before': {
                                transform: 'scaleY(1)',
                                opacity: '1',  
                            },
                            '&:after': {
                                transform: 'scaleY(1)',
                                opacity: '1'
                            }    
                        },
                        '#countries-list-item-mobile': {
                            
                        }
                    }}>
                        {
                            isMobile ? 
                                <Grid item sm={12}>
                                        <Select 
                                            id="countries-list-item-mobile"
                                            value={countryClicked}

                                            onChange={(e) => {
                                                setCountryClicked(e.target.value)
                                            }}
                                            sx={{
                                                width: '90vw !important'
                                            }}
                                            >
                                        {countries.map((e) => {
                                        return (
                                            <MenuItem value={e} >{e}</MenuItem>
                   
                                        )
                                        })}
                                        </Select>
                                         
                                </Grid>
                            :
                            <Grid item sm={12}>
                            <ul>
                            {
                            countries.map((e) => {
                                return (
                                    <li>
                                        <Typography onClick={() => {
                                            setCountryClicked(e)
                                        }} className="countries-list-item">{e}</Typography>
                                    </li>
                                )
                            })
                            }
                        </ul>
                        </Grid>
                        }  
                    </Grid>
                    <Grid container sx={{
                        backgroundColor: '#F5F5F5',
                        textAlign: isMobile ? 'center !important' : 'left',
                        justifyContent: isMobile ? 'center !important' : 'left',
                        alignItems: isMobile ? 'center !important' : 'left',
    
                        paddingLeft: isMobile ? '0' : '10%',
                        paddingRight: isMobile ? '0' : '10%',
                    }}>
                        <Grid item sm={12} p={0} sx={{
                            paddingBottom: '35px'
                        }}>
                        <OfficeMap 
                        markerLabel="Marker Label"
                        markers={[
                             {
                                 position: {
                                    lat: coordinates.lat, 
                                    lng: coordinates.lng
                                }
                             }
                         ]
                         }
                    />
                    </Grid>
                    </Grid>
                    <Grid container columnSpacing={{md: '150px', xl: '150px'}} sx={{backgroundColor: '#F5F5F5', paddingLeft: isMobile ? '0' : '10%',
                        paddingRight: isMobile ? '0' : '10%', flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'center' : 'left', justifyContent: isMobile ? 'center' : 'left', textAlign: isMobile ? 'center' : 'left'}}>
                    <Grid item sm={3} sx={{
                        marginBottom: isMobile ? '30px' : '0',
                        '.our-office-address-title': {
                            fontSize: isMobile ? '24px' : '25px',
                            fontFamily: 'Roboto Slab !important',
                            fontWeight: 'bold',
                            color: '#0069A9',
                            marginBottom: '15px'
                        },
                        '.our-office-address-text': {
                            fontSize: isMobile ? '14px' : '18px',
                            fontFamily: 'Roboto Slab !important',
                            marginX: isMobile ? '20%' : '0',
                            color: '#6C757D',
                            wordWrap: 'break-word'
                        }
                    }}>
                        <img src={LocationLogo} style={{paddingBottom: '15px'}}/>
                        <Typography className="our-office-address-title">Address</Typography>
                        <Typography className="our-office-address-text">{address}</Typography>
                    </Grid>
                    <Grid item sm={3} sx={{
                        marginBottom: isMobile ? '30px' : '0',
                        '.our-office-phone-title': {
                            fontSize: isMobile ? '24px' : '25px',
                            fontFamily: 'Roboto Slab !important',
                            fontWeight: 'bold',
                            color: '#0069A9',
                            marginBottom: '15px'
                        },
                        '.our-office-phone-text': {
                            fontSize: isMobile ? '14px' : '18px',
                            fontFamily: 'Roboto Slab !important',
                            color: '#6C757D',
                            wordWrap: 'break-word'
                        }
                    }}>
                        <img src={PhoneLogo} style={{paddingBottom: '15px'}}/>
                        <Typography className="our-office-phone-title">Phone</Typography>
                        <Typography className="our-office-phone-text">{phone}</Typography>
                    </Grid>
                    <Grid item sm={3} sx={{
                        marginBottom: isMobile ? '30px' : '0',
                        '.our-office-email-title': {
                            fontSize: isMobile ? '24px' : '25px',
                            fontFamily: 'Roboto Slab !important',
                            fontWeight: 'bold',
                            color: '#0069A9',
                            marginBottom: '15px'
                        },
                        '.our-office-email-text': {
                            fontSize: isMobile ? '14px' : '18px',
                            fontFamily: 'Roboto Slab !important',
                            color: '#6C757D',
                            wordWrap: 'break-word'
                        }
                    }}>
                        <img src={EmailLogo} style={{paddingBottom: '15px'}}/>
                        <Typography className="our-office-email-title">Email</Typography>
                        <Typography className="our-office-email-text">{email}</Typography>
                    </Grid>

                    <Grid item sm={3} sx={{
                        '.our-office-getintouch-title': {
                            fontSize: isMobile ? '24px' : '25px',
                            fontFamily: 'Roboto Slab !important',
                            fontWeight: 'bold',
                            color: '#0069A9',
                            marginBottom: '15px'
                        },
                        '.our-office-address-text': {
                            fontSize: isMobile ? '14px' : '18px',
                            fontFamily: 'Roboto Slab !important',
                            marginX: isMobile ? '20%' : '',
                            color: '#6C757D',
                            wordWrap: 'break-word'
                        }
                    }}>
                        <img src={GetInTouchLogo} style={{paddingBottom: '15px'}}/>
                        <Typography className="our-office-getintouch-title">Get in touch</Typography>
                        <a href='' onClick={(e) => {
                            e.preventDefault();
                            setOpen(true)
                            }} 
                            onMouseOver={() => setHovered(true)} 
                            onMouseOut={() => setHovered(false)}
                        >
                        { !hovered ? <MessageUsButton/> : <HoveredMessageUsButton/> }
                        </a>
                    </Grid>
                    </Grid>

                    <Grid container sx={{
                        backgroundColor: '#F5F5F5',
                        textAlign: isMobile ? 'center !important' : 'right',
                        justifyContent: isMobile ? 'center !important' : 'right',
                        alignItems: isMobile ? 'center !important' : 'right',
    
                        paddingLeft: isMobile ? '0' : '10%',
                        paddingRight: isMobile ? '0' : '10%',
                    }}>
                        <Grid item sm={12} sx={{ paddingBottom: '20px', paddingTop: '50px'}}>
                        <img src={bottomShapeHexagon} style={{ alignSelf: 'right'}}/>
                        </Grid>
                    </Grid>
                    

                


            {/* MODAL FORM */}

            <Dialog
            open={open}
            fullWidth={true}
            onClose={handleClose}
            maxWidth={maxWidth}
            sx={{
                justifyContent: 'center', 
                objectFit: 'cover',               
                textAlign: 'center',
                width: '100%',
                '.modal-title': {
                    fontFamily: 'Roboto Slab !important',
                    textAlign: 'left !important',
                    fontSize: '32px',
                    fontWeight: 'bold',
                    color: '#0069A9',
                    marginX: '0'
                },
                '.modal-description': {
                    fontFamily: 'Roboto Slab !important',
                    textAlign: 'left !important',
                    fontSize: '18px',
                    marginX: isMobile ? '8%' : '5%',
                    marginBottom: '20px'
                },
                '.button-close': {

                }
            }}
        >
            <DialogActions>
            <Button className="button-close" onClick={handleClose}>X</Button>
            </DialogActions>
            <DialogTitle className="modal-title">Get In Touch</DialogTitle>
            <DialogContentText className="modal-description">Win a present when you get in touch with us!</DialogContentText>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: '30px',
                objectFit: 'cover',
                m: 'auto',
                width: 'fit-content',
                minWidth: isMobile ? '300px' : '550px',
                maxWidth: '100vh'
            }}>

            <TextField
            autoFocus
            margin="dense"
            id="firstName"
            placeholder="*First Name"
            type="text"
            variant="outlined"
            className="modal-form-input"
            value={modalFirstName}
            onChange={(e) => {setModalFirstName(e.target.value)}}
            />
            <TextField
            
            margin="dense"
            id="companyName"
            placeholder="*Company Name"
            type="text"
            variant="outlined"
            className="modal-form-input"
            value={modalCompanyName}
            onChange={(e) => {setModalCompanyName(e.target.value)}}
            />
            <TextField
            
            margin="dense"
            id="emailAddress"
            placeholder="*Email Address"
            type="email"
            variant="outlined"
            className="modal-form-input"
            value={modalEmail}
            onChange={(e) => {setModalEmail(e.target.value)}}
            />
            <TextField
            margin="dense"
            id="message"
            placeholder="*Your Message"
            type="text"
            variant="outlined"
            className="modal-form-input"
            value={modalMessage}
            onChange={(e) => {setModalMessage(e.target.value)}}
            />
            <Button className="btnSubmit" onClick={handleFormSubmit} onMouseOver={() => setButtonHovered(true)} onMouseOut={() => setButtonHovered(false)} sx={{
                backgroundColor: '#0061A1',
                marginTop: '10px',
                color: '#fff',
                '&:hover':{
                    backgroundColor: '#00ACE2'
                }
            }}>Submit <SubmitButtonIcon height={!hovered ? "42" : "48"} width={!hovered ? "48" : "55"}/></Button>
            </Box>
        </Dialog>

        <Dialog
            open={promptOpen}
            fullWidth={true}
            onClose={handleClose}
            maxWidth={maxWidth}
            sx={{
                justifyContent: 'center', 
                objectFit: 'cover',               
                textAlign: 'center',
                width: '100%',
                '.modal-title': {
                    fontFamily: 'Roboto Slab !important',
                    textAlign: 'left !important',
                    fontSize: '32px',
                    fontWeight: 'bold',
                    color: '#0069A9',
                    marginX: '0'
                },
                '.modal-description': {
                    fontFamily: 'Roboto Slab !important',
                    textAlign: 'left !important',
                    fontSize: '18px',
                    marginX: isMobile ? '8%' : '5%',
                    marginBottom: '20px'
                },
                '.button-close': {

                }
            }}
        >

            <DialogActions>
            <Button className="button-close" onClick={handleClose}>X</Button>
            </DialogActions>
            <DialogTitle className="modal-title">Message Sent</DialogTitle>
            <DialogContentText className="modal-description">Your message has been submitted. We'll get back to you shortly</DialogContentText>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: '30px',
                m: 'auto',
                width: 'fit-content',
                minWidth: '500px'
            }}>
            <Button className="btnPropmptConfirm" variant="outlined" onClick={handleClose} onMouseOver={() => setButtonHovered(true)} onMouseOut={() => setButtonHovered(false)} sx={{
                backgroundColor: '#fff',
                outlineColor: '#0061A1',
                marginTop: '10px',
                color: '#0061A1',
                '&:hover':{
                    backgroundColor: '#00ACE2',
                    color: '#fff'
                }
            }}>Ok</Button>
            </Box>
        </Dialog>

        
        </HomeAboutSectionContainer>
        
    )
}

export default HomeOurOfficeSection;

