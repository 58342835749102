import { BlogAllArticlesContainer, BlogArticlesContainer } from "./BlogComponents";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TotalAgencyManagementImage from "../../../assets/images/total-agency-management.png"
import IncreasedProductivityImage from "../../../assets/images/increased-productivity.png"
import ControlProfitabilityAndCostsImage from "../../../assets/images/control-profitability-and-costs.png"
import ArticleBarImage from "../../../assets/images/blog-articles-bar.png"
import {articles} from "../../../constants/articles"
import BlogArticleList from "./BlogArticleList";
import Pagination from "@mui/material/Pagination";
import { useState } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const TopSection = () => {
    const articleList = articles
    const articleOfTheMonth = articles[0]
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    //PAGINATION
    const total = 10;
    const [page, setPage] = useState(1);
    const limit = 6;
    const startIndex = 1 + (page - 1) * limit;
    const endIndex = page * limit;
    const dataToDisplay = articleList.slice(startIndex, endIndex);
    
    return(
        <BlogArticlesContainer>
            <Grid container p={0} sx={{
                paddingLeft: '10%',
                paddingRight: '10%',
                backgroundColor: '#0061A1',
                boxSizing: 'border-box',
                width: '100%',
                alignItems: 'center',
                justifyContents: 'center',
                textAlign: 'center',
                paddingTop: '80px',
                paddingBottom: isMobile ? '150px' : '100px', }}>
                <Grid item sm={12} sx={{
                    textAlign: 'left',
                    display: 'flex',
                    flexDirection: 'row',
                    '.articles-title': {
                        fontFamily: 'Roboto Slab !important',
                        fontSize: isMobile ? '24px' : '36px',
                        fontWeight: 300,   
                    }
                }}>
                    <Typography className="articles-title">Articles<img src={ArticleBarImage} style={{position: 'relative', objectFit: 'cover', width: 'auto', maxWidth: isMobile ? '70%' : '80%', paddingLeft:'10px'}}/></Typography>
                </Grid>
                <Grid item sm={12} sx={{
                    paddingTop: isMobile ? '40px' : '80px',
                    position: 'relative',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    '.article-of-the-month-title': {
                        fontSize: isMobile ? '24px' : '36px',
                        fontFamily: 'Roboto Slab',
                        fontWeight: 300,
                        textAlign: 'left !important',
                        marginBottom: '20px'
                    },
                    '.article-of-the-month-section-title': {
                        fontSize: isMobile ? '14px' : '24px',
                        fontWeight: 500,
                        textAlign: 'left'
                    },
                    '.article-of-the-month-image':{
                        width: '100%',
                    }
                }}>
                    <Typography className="article-of-the-month-section-title">Article of the Month</Typography>
                    <Typography className="article-of-the-month-title">{articleOfTheMonth.title}</Typography>
                    <img src={articleOfTheMonth.image} className="article-of-the-month-image"/>
                    <Box sx={{
                        justifyContent: 'center',
                        width: '100%',
                        height: 'fit-content',
                        wordWrap: 'break-word',
                        position: 'absolute',
                        top: '93%',
                        
                        backgroundImage: 'linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(47,98,129,1) 50%, rgba(47,98,129,1) 80%)',
                        '.article-of-the-month-short-detail':{
                            fontSize: '16px',
                            fontFamily: 'Open Sans',
                            fontWeight: 300,
                            color: '#D9F3F9', 
                            alignItems: 'left', 
                            textAlign: 'left', 
                            paddingTop: '5%',
                            marginX: '25px',
                            marginY: '30px',
                        },
                        '.read-more-link': {
                            textDecoration: 'none',
                            fontSize: '16px',
                            fontFamily: 'Open Sans',
                            fontWeight: 300,
                            color: '#D9F3F9', 
                        }
                    }}><Typography className="article-of-the-month-short-detail">{articleOfTheMonth.shortDetail}  <a href={articleOfTheMonth.url} className="read-more-link"><Typography variant="caption">Read More &gt;&gt;</Typography></a></Typography></Box>
                </Grid>
            </Grid>
            
        </BlogArticlesContainer>
    )
}

export default TopSection;