import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { AppState } from "../../store";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import moment from 'moment';
import { getBanners } from "../../store/banner/actions";
import { BannerState } from "../../store/banner/types";
import { Banner } from '../../store/banner/types';
import { HomeContainer } from "../home/fragments/HomeComponents";
import TopSection from "./fragments/BlogBanner";
import { BlogContainer } from "./fragments/BlogComponents";
import MidSection from "./fragments/BlogArticles";
import BottomSection from "./fragments/BlogResources";
import BlogBanner from "./fragments/BlogBanner";
import BlogArticles from "./fragments/BlogArticles";
import ContactUsSection from "../common/ContactUsSection";
import BlogAllArticles from "./fragments/BlogAllArticles";
import BlogResources from "./fragments/BlogResources";




const Blog = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <BlogContainer>
            <BlogBanner/>
            <BlogArticles/>
            <BlogAllArticles/>
            <BlogResources/>
            <ContactUsSection/>
        </BlogContainer>
    );
};


export default Blog;
