export type BannerInputType = string | number | boolean | Banner | Array<Banner>;
export interface BannerInputInterface<T> {
    [key: string]: T;
}

export interface Banner {
    id: string;
    media: string;
    title: string;
}

export interface BannerState {
    banners: Array<Banner>;
    loading: boolean;
}

export const SET_BANNER_STATE = 'set_banner_state';
export interface SetBannerStateAction {
    type: typeof SET_BANNER_STATE;
    payload: BannerInputInterface<BannerInputType>;
}

export type BannerAction = SetBannerStateAction;