import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ReactNode } from "react";

export const appColors = {
 blackHeader: "#111111",
 colorText: "#ccc",
 darkestGray: "#1A2225",
 darkGray: "#222D31",
 mediumGray: "#596164",
 lightGray: "#ccc",
 lightestGray: "#f3f3f3",
 softGray: "#EFF0F2",
 grey: "#989898",
 dark: "#222",
 primary: "#CC2229",
 white: "#ffffff",
 success: "#28A745",
 error: "#DD4C39",
 errorDark: "#C94434",
 warning: "#F39C11",
 warningDark: "#DD8E0F",
 blueHeader: "#141B2E",
 blueHeader2: "#0F1422",
 contactNumber: "#B1B1B1",
 black: "#000000",
 promoSubHeader: "#999999",
};

export const spacing = {
	paddingX: 12,
	paddingY: 7,
};

const theme = createTheme({
 palette: {
	primary: {
		main: "#E11428",
	},
	secondary: {
		main: "#9a9a9a",
	},
	text: {
		primary: "#D9F3F9",
		secondary: "#2F6281",
	},
},
typography: {
	fontFamily: "Open Sans",
},
});

export default function AppThemeProvider({
	children,
}: {
	children: ReactNode;
}) {
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			{children}
		</ThemeProvider>
	);
}
