import Grid from "@mui/material/Grid"
import { HomeAllArticlesContainer } from "./HomeComponents"
import {articles} from "../../../constants/articles"
import HomeArticleList from "./HomeArticlesList";
import Pagination from "@mui/material/Pagination";
import { useState } from "react";
import Box from "@mui/material/Box";
import { HashLink } from 'react-router-hash-link';
const HomeAllArticles = ()  => {
    const articleList = articles
    //PAGINATION
    const total = 10;
    const [page, setPage] = useState(1);
    const limit = 3;
    const startIndex = (page - 1) * limit;
    const endIndex = page * limit;
    const dataToDisplay = articleList.slice(startIndex, endIndex);
    return(
        <HomeAllArticlesContainer>
                <Grid container p={0} sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'relative',
                    boxSizing: 'border-box',
                    width: '100%',
                    alignItems: 'center',
                    justifyContents: 'center',
                    textAlign: 'center',
                    paddingLeft: {xl: '5%', md: '0', sm: '1%'},
                    paddingRight: {xl: '5%',md: '0', sm: '1%'},
                    paddingBottom: '50px'
                    }}>
                    {
                    dataToDisplay.map((p) => {
                        return(
                            <Grid item sm={4} sx={{ }}>
                                <Box key={p.title}>
                                <HomeArticleList
                                    title={p.title}
                                    image={p.image}
                                    articleUrl={p.url}
                                />
                                </Box>
                            </Grid>     
                        )
                    })
                }
                <Grid item sm={12} sx={{
                    paddingTop: '50px', 
                    '.see-more-link': {
                        textDecoration: 'none',
                        fontSize: '16px',
                        fontFamily: 'Open Sans',
                        fontWeight: 300,
                        color: '#D9F3F9', 
                    }
                }}>
                    <HashLink to="/Blog#articles" className="see-more-link">See More &gt;&gt;</HashLink>
                </Grid>
                </Grid>        
            </HomeAllArticlesContainer> 
    )
}
export default HomeAllArticles