import Grid from "@mui/material/Grid"
import { BlogAllArticlesContainer } from "./BlogComponents"
import {articles} from "../../../constants/articles"
import BlogArticleList from "./BlogArticleList";
import Pagination from "@mui/material/Pagination";
import { useState } from "react";
import Box from "@mui/material/Box";

const BlogAllArticles = ()  => {
    const articleList = articles
    //PAGINATION
    const total = 10;
    const [page, setPage] = useState(1);
    const limit = 6;
    const startIndex = 1 + (page - 1) * limit;
    const endIndex = page * limit;
    const dataToDisplay = articleList.slice(startIndex, endIndex);
    return(
        <BlogAllArticlesContainer>
                <Grid container p={0} sx={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContents: 'center',
                    textAlign: 'center',
                    paddingLeft: '10%',
                    paddingRight: '10%',
                    paddingBottom: '50px'
                    }}>
                    {
                    
                    dataToDisplay.map((p) => {
                        return(
                            <Grid item sm={4}>
                                <Box key={p.title}>
                                <BlogArticleList
                                    title={p.title}
                                    image={p.image}
                                    articleUrl={p.url}
                                />
                                </Box>
                            </Grid>     
                        )
                    })
                }

                </Grid>  
                <Pagination />         
            </BlogAllArticlesContainer> 
    )
}
export default BlogAllArticles