import { forwardRef } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YoutubeIcon from "@mui/icons-material/YouTube";
import Logo from "./Logo";
import useDetectTabletLandscape from "../../hooks/useDetectTabletLandscape";
import useIsTabletOrMobile from "../../hooks/useIsTabletOrMobile";

const year = new Date().getFullYear();

const Footer = forwardRef<HTMLElement>((props, ref) => {
  const isTabletLandscape = useDetectTabletLandscape();
  const isTabletOrMobile = useIsTabletOrMobile();
  return (
    <Stack
      ref={ref}
      component='footer'
      direction='column'
      alignItems='center'
      padding={5}
      sx={{
        bgcolor: "#6C757D",
        color: "white",
        marginTop: "auto",
      }}
    >
      <Stack
        // direction={{ xs: "column", md: "row" }}
        direction={isTabletLandscape || isTabletOrMobile ? "column" : "row"}
        justifyContent='center'
        spacing={1}
        marginTop={0}
        sx={{ fontSize: 14, fontFamily: "metropolis,sans-serif !important" }}
      >
        <Typography
          component='p'
          fontFamily='inherit'
          fontSize={{ xs: 14, sm: 16 }}
        >
          © 2022 Rights Reserved by OPSOLUTIONS | Privacy Policy
        </Typography>
      </Stack>
    </Stack>
  );
});

export default Footer;
