import { GoogleMap, Marker, MarkerProps, useJsApiLoader } from '@react-google-maps/api';
import map from 'lodash/map';
import { useState, useCallback } from 'react';

interface BasicMapInterface {
    markerLabel: string;
    markers: Array<MarkerProps>;
}

const OfficeMap = (props: BasicMapInterface) => {
    const [center, setCenter] = useState({
        lat: 14.275175,
        lng: 121.075125
    });


    const onLoad = useCallback(
        () => {
            // do something with load
            if (props.markers.length > 0) {
                setCenter({...props.markers[0].position} as typeof center)
            }

        },
        // eslint-disable-next-line
        []
    )
    
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBs8ZCKdTIR4IvTb-pLr51_1t6hnzuhCC4"
    })
    const renderMarkers = () => {
        return map(props.markers, (marker: MarkerProps, index) => (
            <Marker
                key={`marker-${index+1}`}
                position={marker.position}
                label={{
                    text: props.markerLabel.length > 0 ? props.markerLabel : ' ',
                    color: '#000',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    className: 'gmap-label',
                }}
            />
        ))
    }

    
    return isLoaded ?(
        <GoogleMap
            mapContainerStyle={{ minHeight: 300, width: '100%', position: 'relative' }}
            zoom={17}
            center={center}
            onLoad={onLoad}
            clickableIcons={true}
        >
            {renderMarkers()}
        </GoogleMap>
    ) : <></>
}

export default OfficeMap;