export type ArticleInputType = string | number | boolean | Article | Array<Article>;
export interface ArticleInputInterface<T> {
    [key: string]: T;
}

export interface Article {
    id: string;
    datePosted: string;
    media: string;
    content: string;
    title: string;
}

export interface ArticleState {
    activeArticle?: Article;
    articles: Array<Article>;
    videos: Array<Article>;
    mostPopularArticles: Array<Article>;
    articleSelect: string;
    loading: boolean;
}

export const SET_ARTICLE_STATE = 'set_article_state';
export interface SetArticleStateAction {
    type: typeof SET_ARTICLE_STATE;
    payload: ArticleInputInterface<ArticleInputType>;
}

export type ArticleAction = SetArticleStateAction;