
import { useMediaQuery } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Link } from "react-router-dom";
import bottomShapeHexagon from "../../assets/images/Shape Hexagon.svg"
// import ContactUsLogo from "../../assets/images/Shape Paper Plane.svg"

const ContactUsSectionContainer = (props: BoxProps) => (
    <Box
        { ...props }
        sx={{
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
        }}
    >{props.children}</Box>
)

const ContactUsSection = () => {
    const [hovered, setHovered] = useState(false)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const contactUsLogoColorFill = !hovered ? 'none' : '#0069a9'
    const contactUsLogoPaperplaneColorFill = !hovered ? '#0069a9' : '#d9f3f9'
    const ContactUsLogo = () => {
        return(<svg xmlns="http://www.w3.org/2000/svg" width="47.98" height="42" viewBox="0 0 47.98 42">
    <g id="Shape:_Paper_Plane" data-name="Shape: Paper Plane" transform="translate(-1253.336 -1958)">
      <g id="Polygon_16" data-name="Polygon 16" transform="translate(1253.336 1958)" fill={contactUsLogoColorFill}>
        <path d="M35.985,0,47.98,21,35.985,42H11.995L0,21,11.995,0Z" stroke="none"/>
        <path d="M 12.57538604736328 0.9999961853027344 L 1.151622772216797 21.00010681152344 L 12.57537841796875 41.00021743774414 L 35.40437316894531 41.00021743774414 L 46.82812118530273 21.00010681152344 L 35.40437316894531 0.9999961853027344 L 12.57538604736328 0.9999961853027344 M 11.99494552612305 -3.814697265625e-06 L 35.98481750488281 -3.814697265625e-06 L 47.97974395751953 21.00010681152344 L 35.98481750488281 42.00021743774414 L 11.99493408203125 42.00021743774414 L -3.814697265625e-06 21.00010681152344 L 11.99494552612305 -3.814697265625e-06 Z" stroke="none" fill="#2969a4"/>
      </g>
      <path id="Icon_ionic-ios-send" data-name="Icon ionic-ios-send" d="M27.749,4.557,4.8,14.559a.526.526,0,0,0,.019.956l6.207,3.507a1,1,0,0,0,1.144-.113L24.411,8.357c.081-.069.275-.2.35-.125s-.044.269-.113.35L14.058,20.51a1,1,0,0,0-.1,1.194l4.057,6.508a.528.528,0,0,0,.95-.013L28.455,5.25A.526.526,0,0,0,27.749,4.557Z" transform="translate(1260.823 1964.918)" fill={contactUsLogoPaperplaneColorFill}/>
    </g>
    </svg>
    )
    }
    return (
        <ContactUsSectionContainer sx={{
            backgroundColor: '#F5F5F5',
            alignItems: 'center',
            textAlign: 'center',
            justifyItems: 'center',
            display: 'flex',
        }}>
            <Grid container p={0}>
                <Grid item sm={12} sx={{
                    alignItems: 'center !important',
                    textAlign: 'center !important',
                    justifyItems: 'center !important',
                    marginX : '10%',
                    paddingTop: '95px',
                    '.contact-us-text': {
                        fontSize: '36px',
                        fontWeight: 'bold',
                        color: '#2F6281'
                    }
                }}>
                    <Typography className="contact-us-text">Contact Us To Learn More</Typography>
                    
                </Grid>
                <Grid item sm={12} sx={{
                    display: 'flex',
                    alignItems: 'center !important',
                    justifyContent: 'center !important',
                    textAlign: 'center !important',
                    paddingTop: '40px',
                    paddingBottom: '100px',
                    marginX : '20%',
                    flexDirection: 'row',
                    '.get-in-touch-text': {
                        fontFamily: 'Roboto Slab',
                        color: '#0069A9',
                        fontWeight: 'Bold',
                        fontSize: '25px',
                        alignItems: 'center',
                        justifyItems: 'center',
                        textAlign: 'center',
                        marginLeft: '15px',
                        position: 'relative',
                        display: 'inline-block',
                        textDecoration: 'none'
                        
                    },
                    '.get-in-touch-text:after':{
                        content: "''",
                        position: 'absolute',
                        width: '100%',
                        transform: 'scaleX(0)',
                        height: '2px',
                        bottom: 0,
                        left: 0,
                        backgroundColor: '#0069A9',
                        transformOrigin: 'bottom right',
                        transition: 'transform 0.25s ease-out'

                    },
                    '.get-in-touch-text:hover:after': {
                        transform: 'scaleX(1)',
                        transformOrigin: 'bottom left'
                    },
                    
                }}>
                    <ContactUsLogo  /><Link onMouseOver={() => setHovered(true)} onMouseOut={() => setHovered(false)} className="get-in-touch-text" to="/ContactUs">Get In Touch</Link> 
                </Grid> 
            </Grid>
            <Grid container sx={{
                        // backgroundColor: '#F5F5F5',
                        textAlign: isMobile ? 'center !important' : 'right',
                        justifyContent: isMobile ? 'center !important' : 'right',
                        alignItems: isMobile ? 'center !important' : 'right',
    
                        paddingLeft: isMobile ? '0' : '10%',
                        paddingRight: isMobile ? '0' : '10%',
                    }}>
                        <Grid item sm={12} sx={{ paddingBottom: '20px'}}>
                        <img src={bottomShapeHexagon} style={{ alignSelf: 'right'}}/>
                        </Grid>
            </Grid>
            

        </ContactUsSectionContainer>
    )
}

export default ContactUsSection