import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { ArticleCardContainer, BlogAllArticlesContainer, BlogAllArticlesImageContainer, BlogAllArticlesTextContainer} from "./BlogComponents";

interface BlogArticleProps {
    title: string;
    image: string;
    articleUrl: string;
}

const BlogArticleList = ({title, image, articleUrl}: BlogArticleProps) => {
    
    
    return(
        <ArticleCardContainer>
            
                <BlogAllArticlesImageContainer>   
                        <img  src={image} alt={`${title}`} className="article-image" style={{
                            objectFit: 'cover', 
                            maxWidth: '360px', 
                            // maxHeight: '200px',
                            width: '100%',
                            boxSizing: 'border-box',
                            position: 'relative',
                            backgroundSize: 'cover',
                            backgroundPosition: '50%',
                            outline: '1.5px solid #DEF2F8',
                            outlineOffset: '-15px',
                        }}/>
                    <BlogAllArticlesTextContainer>
                        <Box sx={{
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        maxWidth: '360px',
                        width: '100%',
                        maxHeight: '200px',
                        wordWrap: 'break-word',
                        height: '100%',
                        position: 'absolute',
                        backgroundImage: 'linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(47,98,129,1) 50%, rgba(47,98,129,1) 80%)',
                        '.article-title': {
                            position: 'relative',
                            marginTop: '20%',
                            fontSize: '16px',
                            marginLeft: '5%',
                            marginRight: '5%',
                            fontFamily: 'Open Sans',
                            fontWeight: 300,
                            color: '#D9F3F9', 
                            alignItems: 'left', 
                            textAlign: 'left', 
                        },
                        '.read-more-link': {
                            color: '#D9F3F9', 
                            alignItems: 'right !important',
                            textAlign: 'right !important', 
                            justifyContent: 'right !important', 
                            position: 'relative',
                            fontSize: '16px !important',
                            marginLeft: '5%',
                            marginRight: '5%',
                            fontFamily: 'Open Sans !important',
                            fontWeight: 300,
                        }
                    }}>
                        <Typography className="article-title" textAlign="left" variant="caption">
                            {title}
                        </Typography>
                        <a href={articleUrl} className="read-more-link"><Typography variant="caption">Read More &gt;&gt;</Typography></a>
             
                    </Box>
                        
                    </BlogAllArticlesTextContainer>

                </BlogAllArticlesImageContainer>
        </ArticleCardContainer>
    )
}

export default BlogArticleList