import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { AppState } from "../../store";
// import { getArticles, setArticleState } from "../../store/articles/actions";
// import { Article, ArticleState } from "../../store/articles/types";
import { HomeContainer } from "./fragments/HomeComponents";
import HomeTitleHeader from "./fragments/HomeAboutSection";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import moment from 'moment';
import { getBanners } from "../../store/banner/actions";
import { BannerState } from "../../store/banner/types";
import HomeBanner from "./fragments/HomeBanner";
import { Banner } from '../../../src/store/banner/types';
import HomeAboutSection from "./fragments/HomeAboutSection";
import HomeArticlesSection from "./fragments/HomeArticlesSection";
import HomeOurOfficesSection from "./fragments/HomeOurOfficesSection";
import Header from "../common/Header";

interface HomeIndexProps {
    // setArticleState: typeof setArticleState;
    // article: ArticleState;
    banner: BannerState;
} 

const Home = (props: HomeIndexProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
      
                        
                    

    // useEffect(() => {
    //     dispatch(getArticles())
    //     dispatch(getBanners())
    // }, [dispatch])

    return (
        <HomeContainer>
            <Grid container p={0}>
                <Grid item md={12} xs={12} p={0}>
                    <HomeBanner />
                </Grid>
                <Grid item md={12} xs={12} p={0}>
                    <HomeAboutSection />
                </Grid>
                <Grid item md={12} xs={12} p={0}>
                    <HomeArticlesSection />
                </Grid>
                <Grid item md={12} xs={12} p={0} className="home-contact-us">
                    <HomeOurOfficesSection/>
                </Grid>
            </Grid>
        </HomeContainer>
    );
};

const mapStateToProps = (state: AppState) => ({
    article: state.article,
    banner: state.banner
})

const mapDispatchToProps = {
//   setArticleState,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
