import { Banner } from '../../../store/banner/types';
import { HomeBannerContainer } from './HomeComponents';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel'
import Box from '@mui/material/Box'
import bannerphoto1 from "../../../assets/images/1.png"
import bannerphoto2 from "../../../assets/images/2.png"
import bannerphoto3 from "../../../assets/images/3.png"
import bannerphoto4 from "../../../assets/images/4.png"
import mobilebannerphoto1 from "../../../assets/images/m1.png"
import mobilebannerphoto2 from "../../../assets/images/m2.png"
import mobilebannerphoto3 from "../../../assets/images/m3.png"
import mobilebannerphoto4 from "../../../assets/images/m4.png"
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';


const HomeBanner = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const data = [
        {
            id: "1",
            media : isMobile ? mobilebannerphoto1 : bannerphoto1,
            title : "Support Agile Management & Collaboration"
        },
        {
            id : "2",
            media : isMobile ? mobilebannerphoto2 : bannerphoto2,
            title : "Boost Productivity, Speed, & Efficiency"
        },
        {
            id : "3",
            media : isMobile ? mobilebannerphoto3 : bannerphoto3,
            title : "Increase Profitability & Control Costs"
        },
        {
            id : "4",
            media : isMobile ? mobilebannerphoto4 : bannerphoto4,
            title : "Enhance Client Satisfaction"
        } 
    ]

    return(
        <HomeBannerContainer sx={{backgroundColor: '#2F5767 !important'}}>
            <Box className="carousel-content" sx={{backgroundColor: '#2F5767', paddingBottom: isMobile ? '15%' : '0' }}>
                {(data.length > 0) && <Carousel
                    showArrows={false}
                    showThumbs={false}
                    showStatus={false}
                    autoPlay={true}
                    interval={5000}
                    infiniteLoop={true}
                    dynamicHeight={true}
                    showIndicators={false}
                    selectedItem={0}
                >
                    {data.map((banner: Banner) => {
                        return (
                            <img
                                key={banner.id}
                                src={banner.media}
                                alt={banner.title}
                                style={{height: '100vh', width: '100%'}}
                            />
                        )
                    })}
                </Carousel>
                }
            </Box>
        </HomeBannerContainer>
        
    )
}

export default HomeBanner;