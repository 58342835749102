import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { BlogAllResourcesContainer} from "./BlogComponents";
import TitleChevron from "../../../assets/images/chevron.png"
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
interface BlogResourcesProps {
    title: string;
    image: string;
    resourceUrl: string;
    resourceShortDescription: string;
}

const BlogResourcesList = ({title, image, resourceUrl, resourceShortDescription}: BlogResourcesProps) => {
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return(
        <BlogAllResourcesContainer>
                <Grid container p={0} sx={{paddingTop: '80px'}}>
                    {
                    isMobile ? <><Grid item sm={6} sx={{ alignItems: 'right', textAlign: 'right' }}>
                    <img src={image} alt={`${title}`} style={{ objectFit: 'cover', maxWidth: '600px', maxHeight: '300px' }} />
                </Grid><Grid item sm={6} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'left',
                    '.blog-resource-title': {
                        fontFamily: 'Open Sans !important',
                        fontSize: '24px',
                        fontWeight: 600
                    },
                    '.blog-resource-short-description': {
                        marginTop: '30px',
                        textAlign: 'left !important',
                        alignItems: 'left !important',
                        fontFamily: 'Open Sans !important',
                        marginLeft: { md: '17%', xl: '25.5%' },
                        fontSize: '16px',
                        fontWeight: 300,
                    },
                    '.blog-resource-url': {
                        marginTop: '15px',
                        color: '#D9F3F9',
                        textAlign: 'left !important',
                        alignItems: 'left !important',
                        fontFamily: 'Roboto Slab !important',
                        marginLeft: { md: '17%', xl: '25.5%' },
                        fontSize: '16px',
                        fontWeight: 300,
                    },
                }}>
                        <Typography className="blog-resource-title"><img src={TitleChevron} style={{ width: '10px', height: '24px', marginRight: '25px' }} />Attain Full Resource Transparency</Typography>
                        <Typography className="blog-resource-short-description">{resourceShortDescription}</Typography>
                        <a href={resourceUrl}><Typography variant="caption" className='blog-resource-url'>See More &gt;&gt;</Typography></a>
                    </Grid></>
                    :
                    <><Grid item sm={6} sx={{ alignItems: 'right', textAlign: 'right' }}>
                            <img src={image} alt={`${title}`} style={{ objectFit: 'cover', maxWidth: '600px', maxHeight: '300px' }} />
                        </Grid>
                        <Grid item sm={6} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'left',
                        '.blog-resource-title': {
                            fontFamily: 'Open Sans !important',
                            fontSize: '24px',
                            fontWeight: 600
                        },
                        '.blog-resource-short-description': {
                            marginTop: '30px',
                            textAlign: 'left !important',
                            alignItems: 'left !important',
                            fontFamily: 'Open Sans !important',
                            marginLeft: { md: '17%', xl: '25.5%' },
                            fontSize: '16px',
                            fontWeight: 300,
                        },
                        '.blog-resource-url': {
                            marginTop: '15px',
                            color: '#D9F3F9',
                            textAlign: 'left !important',
                            alignItems: 'left !important',
                            fontFamily: 'Roboto Slab !important',
                            marginLeft: { md: '17%', xl: '25.5%' },
                            fontSize: '16px',
                            fontWeight: 300,
                        },
                    }}>
                        <Typography className="blog-resource-title"><img src={TitleChevron} style={{ width: '10px', height: '24px', marginRight: '25px' }} />Attain Full Resource Transparency</Typography>
                        <Typography className="blog-resource-short-description">{resourceShortDescription}</Typography>
                        <a href={resourceUrl}><Typography className='blog-resource-url'>See More &gt;&gt;</Typography></a>
                    </Grid></>
                    }
                </Grid>
        </BlogAllResourcesContainer>
    )
}

export default BlogResourcesList