import {
    BannerAction,
    BannerState,
    SET_BANNER_STATE
} from './types';

const INITIAL_STATE: BannerState = {
    banners: [],
    loading: false
}

const bannerReducers = (state = INITIAL_STATE, action: BannerAction): BannerState => {
    switch (action.type) {
        case SET_BANNER_STATE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

export default bannerReducers;