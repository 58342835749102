import { BlogBannerContainer } from "./BlogComponents";
import BlogBannerImage from "../../../assets/images/blog-banner.png"
import BlogBannerMobileImage from "../../../assets/images/articles-resources-mobile-banner.png"
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconBook from "../../../assets/images/Shape Book.svg"
import IconResources from "../../../assets/images/Shape Resources.svg"
import { useMediaQuery, useTheme } from "@mui/material";
const BlogBanner = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return(
        <BlogBannerContainer>
            <Grid container p={0} sx={{
                backgroundColor: "#00000052"
            }}>
                <Grid item sm={12} p={0} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxSizing: 'border-box',
                    paddingTop: '80px',
                    '.blog-banner-image': {
                        objectFit: 'cover',
                        height: isMobile ? 'auto' : '360px',
                        width: '100%',
                        maxHeight: '100%',
                        opacity: '75%',
                        zIndex: -1,
                        position: 'relative'
                    },
                    '.blog-banner-book-icon': {
                        position: 'absolute',
                        top: isMobile ? '35%' : '63%',
                        left: isMobile ? '10%' : '10%',
                        height: '80px',
                        width: '70px'
                    },
                    '.blog-banner-resources-icon': {
                        position: 'absolute',
                        top: isMobile ? '45%' : '73%',
                        left: isMobile ? '25%' : {md: '14%', xl: '13%'},
                        height: '80px',
                        width: '70px'
                    },
                    '.blog-banner-title-text': {
                        position: 'absolute',
                        top: isMobile ? '70%' : '60%',
                        left: isMobile ? '7%' : '20%',
                        color: '#D9F3F9',
                        fontFamily: 'Roboto Slab !important',
                        fontWeight: 600,
                        fontSize: isMobile ? '24px' : '60px'
                    },
                    '.blog-banner-description' : {
                        position: 'absolute',
                        top: '80%',
                        left: isMobile ? '7%' : {md: '20%', xl: '18%'},
                        color: '#D9F3F9',
                        fontWeight: 600,
                        fontSize: isMobile ? '14px' : '24px'
                    }
                    
                    

                }}>
                    <img src={isMobile ? BlogBannerMobileImage : BlogBannerImage} className='blog-banner-image'/>
                        <img src={IconBook} className='blog-banner-book-icon'/>
                        <img src={IconResources} className='blog-banner-resources-icon'/>

                        <Typography className="blog-banner-title-text" component='div'>Articles and Resources</Typography>
                        <Typography className="blog-banner-description" component='div'>Latest news and trends about Deltek Workbook</Typography>   
                </Grid>
            </Grid>
                
            

        </BlogBannerContainer>
    )
}

export default BlogBanner;