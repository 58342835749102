import { DeltekTopSectionContainer } from "./DeltekComponents";
import deltekBanner from "../../../assets/images/Rectangle 2640.png"
import DeltekMobileBanner from "../../../assets/images/deltek-workbook-mobile-banner.png"
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { relative } from "path";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const TopSection = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return(
        <DeltekTopSectionContainer>
            <Grid container p={0} sx={{
                backgroundColor: "#6C757D"
            }}>
                <Grid item sm={12} p={0} sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    boxSizing: 'border-box',
                    paddingTop: '80px',
                    '.deltek-banner-image': {
                        alignItems: 'center',
                        justifyContent: 'center',
                        objectFit: 'cover',
                        height: 'auto' ,
                        width: '100%',
                        opacity: '50%'
                    },
                    '.deltek-banner-text': {
                        position: 'absolute',
                        top: '60%',
                        left: '10%',
                        color: '#D9F3F9',
                        fontFamily: 'Roboto Slab !important',
                        fontWeight: 600,
                        fontSize: isMobile ? '24px' : '60px' 
                    }
                }}>
                    <img src={isMobile ? DeltekMobileBanner : deltekBanner} className='deltek-banner-image'/>
                    <Typography className="deltek-banner-text">Deltek Workbook</Typography>
                </Grid>
            </Grid>
                
            

        </DeltekTopSectionContainer>
    )
}

export default TopSection;