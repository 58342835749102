import Grid, { GridProps } from '@mui/material/Grid';
import Box, { BoxProps } from '@mui/material/Box';
import { CircularProgress } from '@mui/material';

export const HomeContainer = (props: BoxProps) => (
    <Box
        { ...props }
        sx={{
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            '& .mv-60': {
                margin: '30px 0'
            },
            '& .mb-60': {
                marginBottom: '30px'
            },
            '& .more-button': {
                margin: { md: '0 30px 0 0', sm: '0 40px', xs: '0 30px' }
            },
            '& .fit-content': {
                height: 'fit-content'
            },
            '& a': {
                textDecoration: 'none'
            }
        }}
    >{props.children}</Box>
)





export const HomeAboutSectionContainer = (props: BoxProps) => (
    <Box
        { ...props }
        sx={{
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            alignItems: 'center',
            justifyItems: 'center',
        }}
    >{props.children}</Box>
)

export const HomeBannerContainer = (props: BoxProps) => (
    <Box
        { ...props }
        sx={{
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            // marginTop: "80px",
            '& .carousel-root': {
                width: '100%',
            },
            '& .carousel-root > .carousel': {
                overflow: 'unset'
            },
            '& .carousel .control-dots': {
                position: 'absolute',
                bottom: '-10%'
            },
            '& .carousel .control-dots .dot': {
                boxShadow: 'none',
                background: '#989898',
                width: '8px',
                height: '8px'
            },
            '& .carousel .control-dots .selected': {
                background: '#0b2137'
            },
            '& .carousel .slide iframe': {
                margin: 0,
                width: '100%',
                height: '80%'
            }
        }}
    >{props.children}</Box>
)

export const HomeAllArticlesImageContainer = (props: BoxProps) => (
    <Box
        {...props}
        sx={{
           boxSizing: 'border-box',
           display: 'flex',
           flexDirection: 'column',
           justifyContent: 'center',
           alignItems: 'center',
           paddingTop: '20px',
           '& img': {
               objectFit: 'cover',
               height: '100%',
               width: '100%'
           }
        }}
    >{props.children}</Box>
)

export const HomeAllArticlesTextContainer = (props: BoxProps) => (
    <Box
        {...props}
        sx={{
           boxSizing: 'border-box',
           display: 'flex',
           justifyContent: 'center',
           alignItems: 'center',
           objectFit: 'contain',
           width: '100%'
        }}
    >{props.children}</Box>
)

export const ArticleCardContainer = (props: BoxProps) => (
    <Box
        {...props}
        sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: '100px',
            boxSizing: 'border-box'
        }}
    >{props.children}</Box>
)

export const HomeAllArticlesContainer = (props: BoxProps) => (
    <Box
        { ...props }
        sx={{
            backgroundColor: '#0061A1',
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            alignItems: 'center',
            justifyItems: 'center',
            textAlign: 'center',
            '& p': {
                fontFamily: 'Metropolis Medium !important'
            },
        }}
    >{props.children}</Box>
)

export const HomeArticlesContainer = (props: BoxProps) => (
    <Box
        { ...props }
        sx={{
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            alignItems: 'center',
            justifyItems: 'center',
            // '& .wrapper-black':{
            //     borderBottomColor: '#000',
            //     borderBottomStyle: 'solid',
            //     borderBottomWidth: 5,
            //     marginBottom: 3
            // },
            // '& .wrapper-gray':{
            //     borderBottomColor: '#989898',
            //     borderBottomStyle: 'solid',
            //     borderBottomWidth: 5,
            //     marginBottom: 3
            // },
            // '& .title-black':{
            //     textAlign: 'right',
            //     padding: '0 5px',
            //     color: '#000',
            //     fontWeight: 900,
            //     fontSize: { xs: 25, sm: 30}
            // },
            // '& .title-gray':{
            //     textAlign: 'left',
            //     padding: '0 5px',
            //     color: '#989898',
            //     fontWeight: 900,
            //     fontSize: { xs: 25, sm: 30}
            // },
            // '& .pdl-40-featured': {
            //     paddingLeft: { md: '45px', xs: '20px', sm: '30px' }
            // },
            // '& .pdl-40': {
            //     paddingLeft: { md: '0', xs: '20px', sm: '30px' }
            // }
        }}
    >{props.children}</Box>
)