import Grid, { GridProps } from '@mui/material/Grid';
import Box, { BoxProps } from '@mui/material/Box';


export const BlogContainer = (props: BoxProps) => (
    <Box
        { ...props }
        sx={{
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            '& .mv-60': {
                margin: '30px 0'
            },
            '& .mb-60': {
                marginBottom: '30px'
            },
            '& .more-button': {
                margin: { md: '0 30px 0 0', sm: '0 40px', xs: '0 30px' }
            },
            '& .more-button button': {
                fontSize: { md: '20px', sm: '25px', xs: '16px' },
                fontFamily: 'Metropolis Medium !important'
            },
            '& .fit-content': {
                height: 'fit-content'
            },
            '& a': {
                textDecoration: 'none'
            }
        }}
    >{props.children}</Box>
)

export const BlogArticleOfTheMonthContainer = (props: BoxProps) => (
    <Box
        { ...props }
        sx={{
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            alignItems: 'center',
            justifyItems: 'center',
            '& p': {
                fontFamily: 'Metropolis Medium !important'
            },
            // '& .wrapper-black':{
            //     borderBottomColor: '#000',
            //     borderBottomStyle: 'solid',
            //     borderBottomWidth: 5,
            //     marginBottom: 3
            // },
            // '& .wrapper-gray':{
            //     borderBottomColor: '#989898',
            //     borderBottomStyle: 'solid',
            //     borderBottomWidth: 5,
            //     marginBottom: 3
            // },
            // '& .title-black':{
            //     textAlign: 'right',
            //     padding: '0 5px',
            //     color: '#000',
            //     fontWeight: 900,
            //     fontSize: { xs: 25, sm: 30}
            // },
            // '& .title-gray':{
            //     textAlign: 'left',
            //     padding: '0 5px',
            //     color: '#989898',
            //     fontWeight: 900,
            //     fontSize: { xs: 25, sm: 30}
            // },
            // '& .pdl-40-featured': {
            //     paddingLeft: { md: '45px', xs: '20px', sm: '30px' }
            // },
            // '& .pdl-40': {
            //     paddingLeft: { md: '0', xs: '20px', sm: '30px' }
            // }
        }}
    >{props.children}</Box>
)

export const BlogAllArticlesContainer = (props: BoxProps) => (
    <Box
        { ...props }
        sx={{
            backgroundColor: '#0061A1',
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            alignItems: 'center',
            justifyItems: 'center',
            textAlign: 'center',
            '& p': {
                fontFamily: 'Metropolis Medium !important'
            },
        }}
    >{props.children}</Box>
)

export const BlogAllResourcesContainer = (props: BoxProps) => (
    <Box
        { ...props }
        sx={{
            backgroundColor: '#0061A1',
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            alignItems: 'center',
            justifyItems: 'center',
            textAlign: 'center',
            '& p': {
                fontFamily: 'Metropolis Medium !important'
            },
            '& img': {
                objectFit: 'cover',
                height: '100%',
                width: '100%'
            }
        }}
    >{props.children}</Box>
)

export const BlogArticlesContainer = (props: BoxProps) => (
    <Box
        { ...props }
        sx={{
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            alignItems: 'center',
            justifyItems: 'center',
        }}
    >{props.children}</Box>
)

export const BlogBannerContainer = (props: BoxProps) => (
    <Box
        { ...props }
        sx={{
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            alignItems: 'center',
            justifyItems: 'center',
            '& p': {
                fontFamily: 'Metropolis Medium !important'
            },
        }}
    >{props.children}</Box>
)

export const BlogResourcesContainer = (props: BoxProps) => (
    <Box
        { ...props }
        sx={{
            width: '100%',
            backgroundColor: '#0061A1',
            boxSizing: 'border-box',
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            alignItems: 'center',
            justifyItems: 'center',
            paddingBottom: '30px'
        }}
    >{props.children}</Box>
)

export const BlogAllArticlesImageContainer = (props: BoxProps) => (
    <Box
        {...props}
        sx={{
           boxSizing: 'border-box',
           display: 'flex',
           flexDirection: 'column',
           justifyContent: 'center',
           alignItems: 'center',
           textAlign: 'center',
           paddingTop: '20px',
           '& img': {
               height: '100%',
               width: '100%'
           }
        }}
    >{props.children}</Box>
)

export const BlogAllArticlesTextContainer = (props: BoxProps) => (
    <Box
        {...props}
        sx={{
           boxSizing: 'border-box',
           display: 'flex',
           justifyContent: 'center',
           alignItems: 'center',
           objectFit: 'contain',
           width: '100%'
        }}
    >{props.children}</Box>
)

export const ArticleCardContainer = (props: BoxProps) => (
    <Box
        {...props}
        sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: '100px',
            boxSizing: 'border-box',
            position: 'relative',
        }}
    >{props.children}</Box>
)