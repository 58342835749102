import { DeltekMidSectionContainer } from "./DeltekComponents";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TotalAgencyManagementImage from "../../../assets/images/total-agency-management.png"
import IncreasedProductivityImage from "../../../assets/images/increased-productivity.png"
import ControlProfitabilityAndCostsImage from "../../../assets/images/control-profitability-and-costs.png"
const TopSection = () => {
    return(
        <DeltekMidSectionContainer>
            <Grid container p={0} sx={{
                backgroundColor: '#0061A1',
                width: '100%',
                alignItems: 'center',
                justifyItems: 'center',
                paddingTop: '80px',
                paddingLeft: '10%',
                paddingRight: '10%',
                paddingBottom: '100px' }}>

                <Grid item sm={4} p={0} sx={{
                   alignItems: 'center',
                   justifyItems: 'center',
                   textAlign: 'center',
                   paddingRight: {sm: '30px', xs: '0px'},
                   paddingTop: {sm: '0px', xs: '80px', xl: '35px'},
                   '.total-agency-management-image': {
                        maxHeight: '200px',
                        maxWidth: '200px'
                    },
                    '.total-agency-management-title': {
                        fontFamily: 'Open Sans !important',
                        paddingTop: '50px',
                        fontSize: '24px',
                        fontWeight: 600,
                        paddingBottom: '30px'
                    },
                    '.total-agency-management-text': {
                        fontFamily: 'Open Sans !important',
                        fontSize: '18px',
                        textAlign: 'left !important',
                    }

                }}>
                    <img src={TotalAgencyManagementImage} className='total-agency-management-image'/>
                    <Typography className='total-agency-management-title'>Total Agency Management</Typography>
                    <Typography className='total-agency-management-text'>Streamline projects, people, and finance from start to finish with a best practice solution designed to help you grow.</Typography>
                </Grid>
                <Grid item sm={4} p={0} sx={{
                    alignItems: 'center',
                    justifyItems: 'center',
                    textAlign: 'center',
                    paddingRight: {sm: '30px', xs: '0px'},
                    paddingTop: {sm: '0px', xs: '80px'},
                    '.increased-productivity-image': {
                        maxHeight: '200px',
                        maxWidth: '200px'
                    },
                    '.increase-productivity-title': {
                        fontFamily: 'Open Sans !important',
                        paddingTop: '50px',
                        fontSize: '24px',
                        fontWeight: 600,
                        paddingBottom: '30px'
                    },
                    '.increase-productivity-text': {
                        fontFamily: 'Open Sans !important',
                        fontSize: '18px',
                        textAlign: 'left !important'
                    }
                }}>
                    <img src={IncreasedProductivityImage} className='increased-productivity-image'/>
                    <Typography className='increase-productivity-title'>Increase Productivity</Typography>
                    <Typography className='increase-productivity-text'>Improve project and resource planning to increase productivity and optimize utilization</Typography>
                </Grid>
                <Grid item sm={4} p={0} sx={{
                    alignItems: 'center',
                    justifyItems: 'center',
                    textAlign: 'center',
                    paddingRight: {sm: '30px', xs: '0px'},
                    paddingTop: {sm: '0px', xs: '80px'},
                    '.control-profitability-and-costs-image': {
                        maxHeight: '200px',
                        maxWidth: '200px'
                    },
                    '.control-profitability-and-costs-title': {
                        fontFamily: 'Open Sans !important',
                        paddingTop: '50px',
                        fontSize: '24px',
                        fontWeight: 600,
                        paddingBottom: '30px'
                    },
                    '.control-profitability-and-costs-text': {
                        fontFamily: 'Open Sans !important',
                        fontSize: '18px',
                        textAlign: 'left'
                    }

                }}>
                    <img src={ControlProfitabilityAndCostsImage} className='control-profitability-and-costs-image'/>
                    <Typography className='control-profitability-and-costs-title'>Control Profitability And Costs</Typography>
                    <Typography className='control-profitability-and-costs-text'>Increase profitability and cost control by accurately scoping, and increasing efficiency, and reducing delays</Typography>
                </Grid>
            </Grid>
        </DeltekMidSectionContainer>
    )
}

export default TopSection;