import Grid, { GridProps } from '@mui/material/Grid';
import Box, { BoxProps } from '@mui/material/Box';


export const DeltekContainer = (props: BoxProps) => (
    <Box
        { ...props }
        sx={{
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
        }}
    >{props.children}</Box>
)

export const DeltekTopSectionContainer = (props: BoxProps) => (
    <Box
        { ...props }
        sx={{
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            alignItems: 'center',
            justifyItems: 'center',
    
        }}
    >{props.children}</Box>
)

export const DeltekMidSectionContainer = (props: BoxProps) => (
    <Box
        { ...props }
        sx={{
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            alignItems: 'center',
            justifyItems: 'center',
            '& p': {
                fontFamily: 'Metropolis Medium !important'
            },
        }}
    >{props.children}</Box>
)

export const DeltekBottomSectionContainer = (props: BoxProps) => (
    <Box
        { ...props }
        sx={{
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            alignItems: 'center',
            justifyItems: 'center',
            '& p': {
                fontFamily: 'Metropolis Medium !important'
            },
        }}
    >{props.children}</Box>
)