import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { AppState } from "../../store";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import moment from 'moment';
import { getBanners } from "../../store/banner/actions";
import { BannerState } from "../../store/banner/types";
import { Banner } from '../../../src/store/banner/types';
import { HomeContainer } from "../home/fragments/HomeComponents";
import TopSection from "./fragments/TopSection";
import { DeltekContainer } from "./fragments/DeltekComponents";
import MidSection from "./fragments/MidSection";
import BottomSection from "./fragments/BottomSection";
import CornerDesign from "../../assets/images/corner-design.svg"
import ContactUsSection from "../common/ContactUsSection";



const Deltek = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
    const isDesktop = useMediaQuery(theme.breakpoints.down('xl'));
    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <DeltekContainer>
            <TopSection/>
            <MidSection/>
            <img src={CornerDesign} style={{position: 'absolute', top: isDesktop ? '21%' : '22.5%', maxHeight: '600px', maxWidth: '500px', zIndex: -1, opacity: '.5'}}/>
            <BottomSection/>
            <ContactUsSection/>
        </DeltekContainer>
    );
};

export default Deltek;
