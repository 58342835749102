import Box from "@mui/material/Box";
import opsLogo from "../../assets/images/Logo OPS.png";
import deltekLogo from "../../assets/images/Logo Deltek.png";
import Grid from "@mui/material/Grid";
type LogoProps = {
  size: "regular" | "medium" | "small" | "mobilebanner";
};

const Logo = ({ size = "regular" }: LogoProps): JSX.Element => {
  const width = size === "regular" ? 160 : size === "medium" ? 180 : size === "mobilebanner" ? 200 : 140;
  const height = size === "regular" ? 40 : size === "medium" ? 100 : size === "mobilebanner" ? 45 : 30;

  return (
      
      <Box
      sx={{
        maxHeight: `${height}px`,
        width: "auto",
        display: "flex",
      }}
     >
      
      <img
        src={opsLogo}
        alt='Office Productivity Solutions Inc.'
        style={{
          maxHeight: height,
          objectFit: "cover",
          backfaceVisibility: "hidden",
          transform: "translateZ(0)",
          imageRendering: "-webkit-optimize-contrast",
          verticalAlign: "middle",
        }}
      />
      <img
        src={deltekLogo}
        alt='Deltek Workbook'
        style={{
          maxHeight: height,
          objectFit: "cover",
          paddingLeft: size === 'regular' ? 20 : 50,
          backfaceVisibility: "hidden",
          transform: "translateZ(0)",
          imageRendering: "-webkit-optimize-contrast",
          verticalAlign: "middle",
        }}
      />
      
    </Box>
  );
};

export default Logo;
