import { createTheme, ThemeProvider } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import Footer from "./components/common/Footer";
import Header from "./components/common/Header";
import Home from "./components/home/Home";
import { AppState } from "./store";
import { SystemState } from "./store/system/types";

import ScrollToTop from "./components/common/ScrollToTopRoute";
import Deltek from "./components/deltekWorkbook/Deltek";
import Blog from "./components/blog/Blog";
import ContactUs from "./components/contactUs/ContactUs"
export const deltekWorkbookTheme = createTheme({
    palette: {
        primary: {
            main: '#2F6281',
            contrastText: '#FFF'
        },
        secondary: {
            main: '#2F6281',
            contrastText: '#FFF'
        }
    }
})

interface AppProps {
    system: SystemState;
}

function App(props: AppProps): JSX.Element {
    const footerRef = React.useRef<HTMLElement>(null);

    // scroll to footer handler
    function scrollToFooter() {
        footerRef.current?.scrollIntoView({ behavior: "smooth" });
    }
    const { system: { redirectTo }} = props;
    return (
        <ThemeProvider theme={deltekWorkbookTheme}>
            <Router>
                <Header/>
                    <Switch>
                        <Route path='/home'>
                            <ScrollToTop />
                            <Home />
                        </Route>
                        <Route path='/DeltekWorkbook'>
                            <Deltek/>
                        </Route>
                        <Route path='/Blog'>
                            <Blog />
                        </Route>
                        <Route path='/ContactUs'>
                            <ContactUs />
                        </Route>
                        <Route path='/'>
                            <ScrollToTop />
                            <Redirect to='/home' />
                        </Route>
                    </Switch>
                <Footer ref={footerRef} />
            </Router>
        </ThemeProvider>
    );
}

export const mapStateToProps = (state: AppState) => ({
    system: state.system,
});

export default connect(mapStateToProps)(App);
