import { HomeAboutSectionContainer } from "./HomeComponents"
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import articlesRuler from "../../../assets/images/Group 8513.svg";
import HomeAllArticles from "./HomeAllArticles";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
// interface HomeTitleHeaderProps {
//     titleBlack: string;
//     titleGray: string;
//     titleBlackFeatured?: boolean;
// }

const HomeArticlesSection = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <HomeAboutSectionContainer>
            <Grid id="articles" container p={0} sx={{
                backgroundColor: '#0061A1',
                paddingTop: isMobile ? '20%' : '10%',
            }}>
                <Grid container p={0} sx={{
                    marginY: {md: '144px'} ,
                    marginX: '10%'
                    
                    }}>
                    <Grid item sm={12} p={0} sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignContent: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        '.articles-title': {
                            fontFamily: 'Roboto Slab !important',
                            fontSize: isMobile ? '24px' : '36px',
                            fontWeight: 300,
                            marginRight: isMobile ? '5px' : ''
                        },
                        '& img': {
                            display: 'flex',
                            width: {xl: '80%', md: '90%', xs: '60vw'},

                        }

                    }}>
                        <Typography className="articles-title">Articles</Typography><img src={articlesRuler} />
                    </Grid>
                    <Grid item sm={12} p={0}>
                        <HomeAllArticles/>
                    </Grid>
                </Grid>
                
            </Grid>
        </HomeAboutSectionContainer>
    )
}

export default HomeArticlesSection;